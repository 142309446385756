import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HelpersService} from '../../service/helpers.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SkladService} from '../../service/sklad.service';
import {DialogService} from '../../components/dialog/dialog.service';
import {HubsService} from '../../service/hubs.service';
import {AppComponent} from '../../app.component';
import {ClientSettingKey, File, Order, ReturnAct, User} from '../../service/models';
import {forEach} from '@angular/router/src/utils/collection';
import {ReturnActService} from '../../service/return-act.service';
import {ReturnOrderModalComponent} from '../return-order-modal/return-order-modal.component';
import {CurrentUserService} from '../../service/current-user.service';
import {FileDownloadService} from '../../http/file-download.service';

@Component({
    selector: 'app-return-act-prepare',
    templateUrl: './return-act-prepare.component.html',
    styleUrls: ['./return-act-prepare.component.scss'],
})
export class ReturnActPrepareComponent implements OnInit {

    @ViewChild('input_barcode') inputBarcode: ElementRef;
    public returnActId: number;
    public returnAct: ReturnAct;

    public isLoading = false;
    public isReturnActExcelLoading = false;
    public isTtnExcelLoading = false;
    public isSuggestLoading = false;
    public isAddOrderDisabled = true;
    public isStatusFormedLoading = false;

    public canMarkLostGoods = false;

    public suggestOrders: any[] = []; // заказы с товарами к возврату

    public message = '';
    private messageTimeout;
    public messageStyle = 'alert-warning';

    public signedMessageOk: boolean = false;
    public signedMessageErr: boolean = false;
    private signedTimeout;


    public goodsCount = 0;

    public orderWithGoods: any;
    public orderWithPlaces: any;

    public selectedGoods: number[] = []; // id отсканированных товаров
    public selectedPlaces: number[] = []; // id отсканированных штрихкодов

    private isSingleAct = false; // помещать все товары заказа в один возвратный акт
    public user: User = null;

    public readonly I_FILE_TYPE_RETURN_ACT_IMAGE = {id: File.TYPE_RETURN_ACT_IMAGE, explain: ''};
    public isCanAccessScans: boolean = false;
    public isCanUploadScans: boolean = false;
    public isScanUploadVisible: boolean = false;
    public loadingFilesList: boolean = false;

    constructor(
        public helpers: HelpersService,
        private route: ActivatedRoute,
        public api: ReturnActService,
        protected dialog: DialogService,
        protected app: AppComponent,
        public hubsService: HubsService,
        protected currentUser: CurrentUserService,
        private fileDownloadService: FileDownloadService,
    ) {
    }

    ngOnInit() {
        this.currentUser.get().subscribe((user: User) => {
            this.user = user;
        });

        this.canMarkLostGoods = this.helpers.checkPermissions('storage:lost-orders-admin');
        this.isCanUploadScans = this.helpers.checkPermissions('files:return-act-image:upload');
        this.isCanAccessScans = this.helpers.checkPermissions('files:return-act-image:download') || this.isCanUploadScans;

        this.route.params.subscribe((params) => {
            this.returnActId = +params['id'];

            this.getReturnAct();
        });
    }

    isPrintByPlaces() {
        return (this.returnAct.type === ReturnAct.TYPE_UNOPENED);
    }

    getReturnAct() {
        this.isLoading = true;
        this.api.getActWithGoods(this.returnActId).subscribe((data) => {
            this.returnAct = data;

            this.isSingleAct = this.helpers.getClientSetting(this.returnAct.client.settings, ClientSettingKey.RETURN_ALL_GOODS_WITH_SINGLE_ACT)

            this.getGoodsCount();

            this.getOrdersForReturn();
            this.isLoading = false;

            setTimeout(() => {
                this.setFocusOnInput();
            }, 200);
        });
    }

    canEditAct() {
        return (this.returnAct.status === ReturnAct.STATUS_NEW)
    }

    setStatusFormed() {
        const dialog = this.dialog.confirm('Перевести акт в статус Сформирован?').subscribe(result => {
            dialog.unsubscribe();
            if (result) {
                this.isStatusFormedLoading = true;
                this.api.setReturnActFormed(this.returnActId).subscribe((data) => {
                    this.returnAct = data
                    this.isStatusFormedLoading = false;
                });
            }
        });
    }

    onGoodDelete(orderId, goodId) {
        const dialog = this.dialog.confirm('Удалить товар из возвратного акта?').subscribe(result => {
            dialog.unsubscribe();
            if (result) {

                this.isLoading = true;
                this.api.removeGoodFromReturnAct({
                    return_act_id: this.returnAct.id,
                    good_id: goodId,
                }).subscribe((data) => {

                    this.isLoading = false;
                    this.removeGoodFromReturnAct(orderId, goodId)
                    this.orderWithGoods = null;
                    this.orderWithPlaces = null;

                    this.getOrdersForReturn()

                }, error => {
                    this.isLoading = false;
                });
            }
        });
    }

    onOrderDelete(orderId) {
        const dialog = this.dialog.confirm('Удалить заказ из возвратного акта?').subscribe(result => {
            dialog.unsubscribe();
            if (result) {
                this.isLoading = true;
                this.api.removeOrderFromReturnAct({
                    return_act_id: this.returnAct.id,
                    order_id: orderId,
                }).subscribe((data) => {
                    this.isLoading = false;
                    this.removeOrderFromReturnAct(orderId)

                    this.getOrdersForReturn()
                }, error => {
                    this.isLoading = false;
                });
            }
        });
    }


    getGoodsCount() {
        let sum = 0;
        for (let i = 0; i < this.returnAct.orders.length; i++) {
            sum += this.returnAct.orders[i].returned_goods.length;
        }
        this.goodsCount = sum;
    }

    setFocusOnInput() {
        if (this.inputBarcode) {
            this.inputBarcode.nativeElement.value = '';
            this.inputBarcode.nativeElement.focus();
        }
    }

    getOrdersForReturn() {
        if (!this.canEditAct()) {
            return
        }
        this.isSuggestLoading = true;
        this.suggestOrders = [];
        this.api.getOrdersForReturn(this.returnActId)
            .subscribe(data => {
                this.isSuggestLoading = false;
                this.suggestOrders = data;

            }, error => {
                this.isSuggestLoading = false;
            });
    }

    onGoodSelect(event) {

        if (event.target.checked) {
            this.selectedGoods.push(Number(event.target.value))
        } else {
            const index = this.selectedGoods.indexOf(Number(event.target.value));
            if (index !== -1) {
                this.selectedGoods.splice(index, 1);
            }
        }
    }

    addToSelectedPlaces(id) {
        this.setScannedSign(true);
        const index = this.selectedPlaces.indexOf(id);
        if (index === -1) {

            this.selectedPlaces.push(id)

            const bNum = this.findIndexInArrayById(this.orderWithPlaces.bar_codes, id)
            if (bNum !== -1) {
                this.orderWithPlaces.bar_codes[bNum].scaned = true
            }
        }

        const notScanedPlaces = this.orderWithPlaces.bar_codes.find((item: any) => {
            if (item.scaned != true) {
                return item
            }
        });

        if (!notScanedPlaces) {
            this.addOrderToReturnAct()
        }
    }

    /**
     * отрезает у маркировки криптохвост
     * @param search
     */
    prepareMark(search) {
        const i = search.indexOf('91');
        let markSubs = ''
        if (i > -1) {
            markSubs = search.substr(0, i)
        } else {
            markSubs = search
        }
        try {
            return btoa(markSubs);
        } catch (e) {
            return markSubs;
        }


    }

    allPlacesScaned() {
        const notScaned = this.orderWithPlaces.bar_codes.find((item: any) => {
            return item.scaned !== true;
        });

        return !notScaned
    }

    findGoodByEan(search) {
        let scanedGood = this.orderWithGoods.refuse_goods.find((item: any) => {
            if (!item.eans) {
                return false;
            }
            const finded = item.eans.find((ean: any) => {
                return (ean.good_ean === search)
            });

            return finded;
        });
        if (!scanedGood) {
            scanedGood = this.orderWithGoods.refuse_goods.find((item: any) => {
                if (item.vendor_code === search) {
                    return item
                }
                if (item.mark === this.prepareMark(search)) {
                    return item;
                }
            });
        }


        if (!scanedGood) {
            console.log('мы не нашли штрихкод')
            this.setScannedSign(false)
            //this.setMessage('Мы не нашли такой штрихкод у товаров заказа', true)
            return;
        }
        this.setScannedSign(true)

        scanedGood.checked = true;
        const id = scanedGood.id

        const index = this.selectedGoods.indexOf(id);
        if (index === -1) {
            this.selectedGoods.push(id);
        }

        const notScanedGood = this.orderWithGoods.refuse_goods.find((item: any) => {
            if (item.checked != true) {
                return item
            }
        });
        if (!notScanedGood) {
            // console.log('все товары просканированы')
            this.addGoodsToReturnAct()
        }
    }

    setScannedSign(isSigned) {

        if (isSigned) {
            this.signedMessageOk = true;
            this.signedMessageErr = false;


        } else {
            this.signedMessageErr = true;
            this.signedMessageOk = false;
        }
        if (this.signedTimeout) {
            clearTimeout(this.signedTimeout);
        }

        this.signedTimeout = setTimeout(() => {
            this.signedMessageErr = false;
            this.signedMessageOk = false;
        }, 10000);

    }

    addOrderToReturnAct() {
        this.isLoading = true;
        this.api.addOrderToReturnAct(
            {
                order_id: this.orderWithPlaces.id,
                places: this.selectedPlaces,
                return_act_id: this.returnAct.id,
            }).subscribe(data => {
            for (let i = 0; i < data.length; i++) {
                this.goodsCount += data[i].returned_goods.length;
                this.addScannedGoodsToAct(data[i])

            }
            this.setMessage('Заказ добавлен в акт')
            this.isLoading = false;
            //  убираем отсканированный заказ из пожеланий
            this.hideOrderFromSuggest(this.orderWithPlaces.id);
            this.orderWithPlaces = null;
            this.selectedPlaces = [];
            this.setFocus()
        }, error => {
            this.isLoading = false;
        });
    }

    addGoodsToReturnAct() {
        this.isLoading = true;
        this.api.addGoodsToReturnAct({goods: this.selectedGoods, return_act_id: this.returnAct.id}).subscribe(data => {
            for (let i = 0; i < data.length; i++) {
                this.goodsCount += this.selectedGoods.length;
                this.addScannedGoodsToAct(data[i])

            }
            this.setMessage('Товары добавлены в акт')
            this.isLoading = false;
            this.hideScannedGoodsFromSuggest(this.orderWithGoods.id, this.selectedGoods);
            this.hideScannedGoodsFromOrderWithGoods()
            this.setFocus();
            this.selectedGoods = [];
        }, error => {
            this.isLoading = false;
        });
    }

    markOrderOpened(isOpened: number, order: Order) {
        this.isLoading = true;
        this.api.markOrderOpened({order_id: order.id, is_opened: isOpened}).subscribe(data => {
            this.isLoading = false;
            // здесь надо отработать как после скана
            order.was_opened = isOpened
            if (this.returnAct.type === ReturnAct.TYPE_ANY) {

                this.showOrderForScan(order, null)
            } else {
                this.clearOrderWithGoods()
                this.clearOrderWithPlaces()
            }

            this.isLoading = false;

            this.selectedGoods = [];
        }, error => {
            this.isLoading = false;
        });
    }


    findIndexInArrayById(arr, id) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id == id) {
                return i
            }
        }
        return -1;
    }

    /**
     удаляет товары из выбранного заказа
     **/
    hideScannedGoodsFromOrderWithGoods() {
        if (!this.orderWithGoods) {
            return;
        }
        for (let i = 0; i < this.selectedGoods.length; i++) {
            const gNum = this.findIndexInArrayById(this.orderWithGoods.refuse_goods, this.selectedGoods[i])
            if (gNum !== -1) {
                this.orderWithGoods.refuse_goods.splice(gNum, 1)
            }
        }
        if (this.orderWithGoods.refuse_goods.length === 0) {
            this.orderWithGoods = null;
        }
    }

    hideScannedPlaceFromOrderWithPlaces(placeId) {
        if (!this.orderWithPlaces) {
            return;
        }
        const pNum = this.findIndexInArrayById(this.orderWithPlaces.bar_codes, placeId)
        if (pNum !== -1) {
            this.orderWithPlaces.bar_codes.splice(pNum, 1)
        }
        if (this.orderWithPlaces.bar_codes.length === 0) {
            this.orderWithPlaces = null;
        }

    }


    removeGoodFromReturnAct(orderId, goodId) {
        this.goodsCount--;
        const oNum = this.findIndexInArrayById(this.returnAct.orders, orderId)
        if (oNum === -1) {
            return;
        }
        const gNum = this.findIndexInArrayById(this.returnAct.orders[oNum].returned_goods, goodId)
        if (gNum === -1) {
            return;
        }
        this.returnAct.orders[oNum].returned_goods.splice(gNum, 1)
        if (this.returnAct.orders[oNum].returned_goods.length === 0) {
            this.returnAct.orders.splice(oNum, 1)
        }
    }


    removeOrderFromReturnAct(orderId) {


        const oNum = this.findIndexInArrayById(this.returnAct.orders, orderId)
        if (oNum !== -1) {
            this.goodsCount -= this.returnAct.orders[oNum].returned_goods.length;
            this.returnAct.orders.splice(oNum, 1)
            return;
        }
    }


    addScannedGoodsToAct(order) {

        const oNum = this.findIndexInArrayById(this.returnAct.orders, order.id)
        if (oNum === -1) {
            this.returnAct.orders.unshift(order)
            return;
        }


        for (let i = 0; i < order.returned_goods.length; i++) {
            const gNum = this.findIndexInArrayById(this.returnAct.orders[oNum].returned_goods, order.returned_goods[i].id)
            if (gNum === -1) {
                this.returnAct.orders[oNum].returned_goods.push(order.returned_goods[i])
            }
        }
    }


    hideScannedGoodsFromSuggest(orderId: number, goods: number[]) {

        const oNum = this.findIndexInArrayById(this.suggestOrders, orderId)
        if (oNum === -1) {
            console.log('Заказ не найден ')
            return;
        }

        // прячем все отсканированные товары
        for (let i = 0; i < goods.length; i++) {
            this.hideGoodFromSuggest(this.suggestOrders[oNum], goods[i])
        }

        // если нет не спрятанных товаров, прячем и заказ тоже
        const notChecked = this.suggestOrders[oNum].refuse_goods.find((item: any) => {
            return item.checked !== true;
        });

        if (!notChecked) {
            this.suggestOrders[oNum].hide = 1;
        }
    }


    hideGoodFromSuggest(order, id) {
        const gNum = this.findIndexInArrayById(order.refuse_goods, id)

        if (gNum === -1) {
            console.log('товар не найден')
        }
        order.refuse_goods[gNum].checked = true;

    }

    hideOrderFromSuggest(orderId) {
        const oNum = this.findIndexInArrayById(this.suggestOrders, orderId)
        if (oNum === -1) {
            console.log('Заказ не найден ')
            return;
        }

        this.suggestOrders[oNum].hide = 1;
    }

    setFocus() {
        setTimeout(() => {
            this.setFocusOnInput();
        }, 200);
    }

    onScan(e) {
        if (!e.target.value) {
            this.setMessage('Не введен штрихкод', true);
            return;
        }

        const search = e.target.value.trim();
        this.isLoading = true;

        if (this.orderWithGoods) {
            this.findGoodByEan(search)
            this.setFocus()
            this.isLoading = false;
            return;
        }

        const orderId = (this.orderWithPlaces) ? this.orderWithPlaces.id : null;
        this.api.scanShkForReturn(
            {
                shk: search,
                return_act_id: this.returnActId,
                order_id: orderId,
            }).subscribe(data => {
                if (!data.order) {
                    this.setScannedSign(false)
                    this.isLoading = false;
                    if (data.error_message) {
                        this.setMessage(data.error_message, true)
                    }
                    return;
                }

                this.setScannedSign(true)
                if (data.is_added_to_act) { // заказ или место добавлены в акт
                    if (data.place) {
                        this.addScannedGoodsToAct(data.order)
                        this.hideScannedPlaceFromOrderWithPlaces(data.place.id)
                        this.getGoodsCount()
                        this.setMessage('Место добавлено в возвратный акт')
                    } else if (data.order) {
                        this.returnAct.orders.unshift(data.order)

                        this.hideOrderFromSuggest(data.order.id)
                        this.setMessage('Заказ добавлен в возвратный акт')
                        this.getGoodsCount()
                        this.orderWithPlaces = null;
                        this.orderWithGoods = null;
                    }

                } else {
                    this.setMessage('');
                    this.showOrderForScan(data.order, data.place)
                    e.target.value = '';

                }
                setTimeout(() => {
                    this.setFocusOnInput();
                }, 200);


                this.isLoading = false;
            },
            error => {
                setTimeout(() => {
                    this.setFocusOnInput();
                }, 200);
                this.isLoading = false;
            })

    }

    showOrderForScan(order, place = null) {

        let isRecentlyAddedOrder = false;
        if (!order.was_opened) { // не вскрытый возврат, сканируем по местам
            this.orderWithGoods = null;
            if (!this.orderWithPlaces) {
                this.setMessage('Сканируйте места заказа')
                this.orderWithPlaces = order;
                isRecentlyAddedOrder = true;
            }

            if (place && (!isRecentlyAddedOrder || order.bar_codes.length != 1 || order.status != Order.STATUS_REFUSE)) {
                this.addToSelectedPlaces(place.id)
            }


            this.isAddOrderDisabled = !this.allPlacesScaned()
        } else { // вскрытый возврат, сканируем по товарам
            this.orderWithPlaces = null;
            if (!this.isSingleAct) {
                this.setMessage('Сканируйте товары заказа')
            } else {
                this.setMessage('Сканируйте все товары заказа')
            }

            this.orderWithGoods = order;
            this.selectedGoods = [];
        }

    }

    clearOrderWithPlaces() {
        this.selectedPlaces = [];
        if (this.orderWithPlaces) {
            this.setMessage('Отложите в сторону отсканированные места!', true)
        }

        this.orderWithPlaces = null;
        this.setFocus()
    }

    clearOrderWithGoods() {
        this.selectedGoods = [];

        if (this.orderWithGoods) {
            this.orderWithGoods = null;
            this.setMessage('Отложите в сторону отсканированные товары!', true)
        }
        this.setFocus()
    }

    setMessage(message, isDanger = false) {
        this.message = message;
        if (isDanger) {
            this.messageStyle = 'alert-danger';
        } else {
            this.messageStyle = ' alert-warning'
        }
        if (this.messageTimeout) {
            clearTimeout(this.messageTimeout);
        }

        this.messageTimeout = setTimeout(() => {
            this.message = '';
        }, 10000);
    }


    canAddGoods() {
        if (!this.orderWithGoods) {
            return false;
        }

        // если в акт можно добавлять только все товары целиком
        if (this.isSingleAct) {
            // надо проверить что все товары отмечены
            return (this.selectedGoods.length === this.orderWithGoods.refuse_goods.length)
        } else {
            // надо проверить, что хотя бы один товар отмечен
            return (this.selectedGoods.length !== 0);
        }
    }

    canDeletePartly(order) {
        return (order.was_opened && !this.isSingleAct);
    }

    /** можно ли менять свойство вскрытый/не вскрытый
     *  @param order
     */
    canChangeOpened(order) {
        if (!order) {
            return false
        }
        if (this.findIndexInArrayById(this.returnAct.orders, order.id) > -1) {
            return false;
        }
        return (order.status === Order.STATUS_REFUSE && order.returned_goods.length === 0)
    }

    canMarkGoodsAsLost() {

    }

    openLostDialog(uid) {
        this.app.createDialog(ReturnOrderModalComponent, {
            order_uid: uid,
        }).onDestroy(data => {
            this.getOrdersForReturn();
        })
    }

    onUploadAcknowledged(uploadedFiles) {
        this.returnAct.files = uploadedFiles;
    }

    onSetReturnActStatus(statusObject) {
        if (!statusObject) {
            return;
        }

        this.isLoading = true;
        this.api.setReturnActStatus(this.returnActId, statusObject.id).subscribe(data => {
            this.getReturnAct();
        }, error => {
            this.isLoading = false;
        })
    }

    onReturnActExcelClick() {
        this.isReturnActExcelLoading = true;
        const fileName = 'Выгрузка VIN_КГТ на FTP';
        this.fileDownloadService
            .downloadFile('/storage/return-acts/getActWithGoodsExcel/' + this.returnActId, fileName + '.xlsx', 'post', {})
            .subscribe(response => {
                this.isReturnActExcelLoading = false;
            }, error => {
                this.isReturnActExcelLoading = false;
            });
    }

    onReturnTTNExcelClick() {
        this.isTtnExcelLoading = true;
        const fileName = 'ТТН для возврата';
        this.fileDownloadService
            .downloadFile('/storage/return-acts/getTtnActWithGoodsExcel/' + this.returnActId, fileName + '.xlsx', 'post', {})
            .subscribe(response => {
                this.isTtnExcelLoading = false;
            }, error => {
                this.isTtnExcelLoading = false;
            });
    }
}
