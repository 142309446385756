import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {FinanceService} from '../../../service/finance.service';
import {FileDownloadService} from '../../../http/file-download.service';

@Component({
  selector: 'app-payments-report-orders',
  templateUrl: './payments-report-orders.component.html',
  styleUrls: ['./payments-report-orders.component.scss']
})
export class PaymentsReportOrdersComponent implements OnInit {
    @Input('query') query;

    public width = 500;
    public windowTop = 100;
    public windowHeight = 100;
    public title = 'Список заказов';

    public orders;

    public ordersLoading: boolean = false;
    public isExcelLoading = false;

    constructor(
        protected app: AppComponent,
        private financeApi: FinanceService,
        private fileDownloadService: FileDownloadService,
    ) {
        if (window.innerWidth > 900) {
            this.width = 900;
        } else {
            this.width = (0.95 * window.innerWidth);
        }

        this.windowHeight = (window.innerHeight - this.windowTop) * 0.9;
    }

    ngOnInit() {
        this.ordersLoading = true;
        this.financeApi.getPaymentsReportOrders(this.query).subscribe(data => {
            this.orders = data;
            this.ordersLoading = false;
        }, error => {
            this.ordersLoading = false;
        });
    }

    onClose() {
        this.app.closeDialog(this);
    }

    onExcelClick() {
        this.isExcelLoading = true;
        const query = this.query;
        const fileName = 'Отчёт по оплатам. Расшифровка ' + query.date_from + ' - ' + query.date_to;

        query.orders_count = this.orders.length;

        this.fileDownloadService
            .downloadFile('/buh/getPaymentsReportOrdersExcel', fileName + '.xlsx', 'post', query)
            .subscribe(response => {
                this.isExcelLoading = false;
            }, error => {
                this.isExcelLoading = false;
            });
    }
}
