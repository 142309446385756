import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {SidebarComponent} from './sidebar/sidebar.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {ClientSelectComponent} from './client-select/client-select.component';
import {CompanySelectComponent} from './company-select/company-select.component';
import {HubSelectComponent} from './hub-select/hub-select.component';
import {BodyModule, GridModule, RowFilterModule} from '@progress/kendo-angular-grid';
import {ClientCardComponent} from './client-card/client-card.component';
import {WindowModule} from '@progress/kendo-angular-dialog';
import {ClientDialogComponent} from './client-card/client-dialog/client-dialog.component';

import {UserCardComponent} from './user-card/user-card.component';
import {UserDialogComponent} from './user-card/user-dialog/user-dialog.component';
import {BackButtonComponent} from './back-button.component';
import {UserSelectComponent} from './user-select/user-select.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {OrderCardComponent} from './order-card/order-card.component';
import {OrderDialogComponent} from './order-card/order-dialog/order-dialog.component';
import {ZorderComponent} from '../components/zorder/zorder.component';
import {ZorderModalComponent} from '../components/zorder/zorder-modal/zorder-modal.component';
import {OrdersMapComponent} from './orders-map/orders-map.component';
import {DocReturnActCardComponent} from './doc-return-act-card/doc-return-act-card.component';
import {DocReturnActDialogComponent} from './doc-return-act-card/doc-return-act-dialog/doc-return-act-dialog.component';



import {AngularYandexMapsModule} from 'angular8-yandex-maps';
import {environment} from '../../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {NgxDaDataModule} from '@kolkov/ngx-dadata';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {DistrictSelectComponent} from './district-select/district-select.component';
import {
    MAT_DATE_LOCALE,
    MAT_TABS_CONFIG, MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule
} from '@angular/material';
import {LocationMapComponent} from './location-map/location-map.component';
import {ZoneSelectComponent} from './zone-select/zone-select.component';
import {StatusSelectComponent} from './status-select/status-select.component';
import { LogistMapComponent } from './logist-map/logist-map.component';
import { MainMapComponent } from './main-map/main-map.component';
import { SubstatusSelectComponent } from './substatus-select/substatus-select.component';
import { RoleCardComponent } from './role-card/role-card.component';
import { RoleDialogComponent } from './role-card/role-dialog/role-dialog.component';
import { RightsFailureComponent } from './rights-failure/rights-failure.component';
import { LabelPrintComponent } from './label-print/label-print.component';
import {NgxPrintModule} from 'ngx-print';
import {NgxBarcodeModule} from 'ngx-barcode';
import { HubDeliveryIntervalSelectComponent } from './hub-delivery-interval-select/hub-delivery-interval-select.component';
import { AddressDialogComponent } from './address-card/address-dialog/address-dialog.component';
import { AddressCardComponent } from './address-card/address-card.component';
import { AddressEditorMapComponent } from './address-editor-map/address-editor-map.component';
import { AddressEditorMapDialogComponent } from './address-editor-map/address-editor-map-dialog/address-editor-map-dialog.component';
import { ChooseDateDialogComponent } from './choose-date-dialog/choose-date-dialog.component';
import { BarcodePrintComponent } from './barcode-print/barcode-print.component';
import {ChangesLoggerModalComponent} from './changes-logger-modal/changes-logger-modal.component';
import {ChangesLoggerComponent} from './changes-logger/changes-logger.component';
import { KendogridFilterComponent } from './kendogrid-filter/kendogrid-filter.component';
import {LsMoneyPipe} from '../system/pipes/ls-money.pipe';
import { JobsProgressComponent } from './jobs-progress/jobs-progress.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {InvoicePrintComponent} from './invoice-print/invoice-print.component';
import {CourierTypeSelectComponent} from './courier-type-select/courier-type-select.component';
import {MatButtonModule} from '@angular/material/button';
import {ClientUsersEditComponent} from './client-users/client-users-edit/client-users-edit.component';
import {NgxMatFileInputModule} from '@angular-material-components/file-input';
import {ClientUsersCreateComponent} from './client-users/client-users-create/client-users-create.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SamedaySelectComponent} from './sameday-select/sameday-select.component';
import {ShiftSelectComponent} from './shift-select/shift-select.component';
import {ScheduleStatusSelectComponent} from './schedule-status-select/schedule-status-select.component';
import {MatListModule} from '@angular/material/list';
import { UniversalEditModalComponent } from './universal-edit-modal/universal-edit-modal.component';
import {TextMaskModule} from 'angular2-text-mask';
import {InvoicePrintMobileComponent} from './invoce-print-mobile/invoice-print-mobile.component';
import {PpActStatusSelectComponent} from './pp-act-status-select/pp-act-status-select.component';
import {PpActPlacesComponent} from '../storage/pp-acts/pp-act-places/pp-act-places.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {TimerComponent} from './timer/timer.component';
import {TerminalStatusSelectComponent} from './terminal-status-select/terminal-status-select.component';
import {TerminalTypeSelectComponent} from './terminal-type-select/terminal-type-select.component';
import {TerminalCardComponent, TerminalCardDialogComponent} from './terminal-card/terminal-card.component';
import {OrderSignatureCardComponent} from './order-signature-card/order-signature-card.component';
import {
    CourierTerminalCardComponent,
    CourierTerminalCardDialogComponent,
} from './courier-terminal-card/courier-terminal-card.component';
import {
    CompanyCommissionChairsCardComponent,
} from './company-commission-chairs/company-commission-chairs-card.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MactStatusSelectComponent} from './mact-status-select/mact-status-select.component';
import {CourierCardComponent} from './courier-card/courier-card.component';
import {AccountSelectComponent} from './account-select/account-select.component';
import {ReturnActClientSelectComponent} from './return-act-client-select/return-act-client-select.component';
import {ReturnActClientWarehouseSelectComponent} from './return-act-client-warehouse-select/return-act-client-warehouse-select.component';
import {ReturnActCreateCardComponent} from './return-act-create-card/return-act-create-card.component';
import {ReturnActStatusSelectComponent} from './return-act-status-select/return-act-status-select.component';
import {ReturnActTypeSelectComponent} from './return-act-type-select/return-act-type-select.component';
import {ReturnActZorderSelectComponent} from './return-act-zorder-select/return-act-zorder-select.component';
import {LogReaderComponent} from './log-reader/log-reader.component';
import {ReturnActReturnReasonSelectComponent} from './return-act-return-reson-select/return-act-return-reason-select.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {KassaReportOrdersComponent} from '../finance/kassa-report/kassa-report-orders/kassa-report-orders.component';
import {KassaReportProblemsComponent} from '../finance/kassa-report/kassa-report-problems/kassa-report-problems.component';
import {EKassirEncashmentModalComponent} from '../finance/e-kassir-encashment-modal/e-kassir-encashment-modal.component';
import {ChartModule} from '@progress/kendo-angular-charts';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DateAdapter, MatOptionModule} from '@angular/material/core';
import {CustomDateAdapter} from '../service/custom-date-adapter';
import {MatSelectModule} from '@angular/material/select';
import {CourierSalaryListComponent} from '../statistics/courier-salary/courier-salary-list/courier-salary-list.component';
import {DailyMeetingOrdersComponent} from '../statistics/daily-meeting/daily-meeting-orders/daily-meeting-orders.component';
import {DailyMeetingClientsComponent} from '../statistics/daily-meeting/daily-meeting-clients/daily-meeting-clients.component';
import {IntervalsStatDetailsComponent} from '../statistics/intervals-stat/intervals-stat-details/intervals-stat-details.component';
import {NewStatOrdersComponent} from '../statistics/new-stat/new-stat-orders/new-stat-orders.component';
import {MatBadgeModule} from '@angular/material/badge';
import {EditorModule} from '@tinymce/tinymce-angular';
import {MatCardModule} from '@angular/material/card';
import {ZoneGroupAddComponent} from '../logistics/zone-redactor/zone-group-add/zone-group-add.component';
import {PenaltyAndComplaintSelectComponent} from './penalty-and-complaint-select/penalty-and-complaint-select.component';
import {IntervalSelectComponent} from './interval-select/interval-select.component';
import {NdsTypeSelectComponent} from './nds-type-select/nds-type-select.component';
import {AgentReportCreateComponent} from '../finance/agent-report/agent-report-create/agent-report-create.component';
import {LsMoneyBuhPipe} from '../system/pipes/ls-money-buh.pipe';
import {UcfirstPipe} from '../system/pipes/ucfirst.pipe';
import {FileUploaderComponent} from './file-uploader/file-uploader.component';
import {OrderBadgesComponent} from './order-badges/order-badges.component';
import {OrderBadgeItemComponent} from './order-badges/order-badge-item/order-badge-item.component';
import {SetCourierArrivedForWorkComponent} from './set-courier-arrived-for-work/set-courier-arrived-for-work.component';
import {HubGateModalComponent} from '../storage/hub-gates/hub-gate-modal/hub-gate-modal.component';
import {CodTypeSelectComponent} from './cod-type-select/cod-type-select.component';
import {NewBankPaymentOrderComponent} from './new-bank-payment-order/new-bank-payment-order.component';
import {
    NewBankPaymentOrderDialogComponent
} from './new-bank-payment-order/new-bank-payment-order-dialog/new-bank-payment-order-dialog.component';
import {SpreadBankPaymentOrderComponent} from './spread-bank-payment-order/spread-bank-payment-order.component';
import {
    SpreadBankPaymentOrderDialogComponent
} from './spread-bank-payment-order/spread-bank-payment-order-dialog/spread-bank-payment-order-dialog.component';
import {InventivActsUploadComponent} from '../storage/inventiv-acts/inventiv-acts-upload/inventiv-acts-upload.component';
import {InventivActsCompareComponent} from '../storage/inventiv-acts/inventiv-acts-compare/inventiv-acts-compare.component';
import {TextCopyComponent} from './text-copy/text-copy.component';
import {AgentReportEditComponent} from '../finance/agent-report/agent-report-edit/agent-report-edit.component';
import {LsNumberPipe} from '../system/pipes/ls-number.pipe';
import {PaymentsReportOrdersComponent} from '../finance/payments-report/payments-report-orders/payments-report-orders.component';
import {PaymentsReportZordersPopupComponent} from '../finance/payments-report-zorders/payments-report-zorders-popup/payments-report-zorders-popup.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MDBBootstrapModule.forRoot(),
        GridModule,
        RowFilterModule,
        WindowModule,
        AngularYandexMapsModule.forRoot({
            apikey: environment.yandex_api_key,
            lang: 'ru_RU',
        }),
        NgMultiSelectDropDownModule,
        HttpClientModule,
        NgxDaDataModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatDialogModule,
        MatInputModule,
        NgxPrintModule,
        NgxBarcodeModule,
        MatProgressBarModule,
        BodyModule,
        MatButtonModule,
        NgxMatFileInputModule,
        MatCheckboxModule,
        MatListModule,
        TextMaskModule,
        MatPaginatorModule,
        MatTabsModule,
        MatSlideToggleModule,
        NgMultiSelectDropDownModule,
    ],
    declarations: [
        ChangesLoggerComponent,
        ChangesLoggerModalComponent,
        SidebarComponent,
        TextCopyComponent,
        ClientSelectComponent,
        CompanySelectComponent,
        CourierTypeSelectComponent,
        IntervalSelectComponent,
        PpActStatusSelectComponent,
        PenaltyAndComplaintSelectComponent,
        ReturnActClientSelectComponent,
        ReturnActReturnReasonSelectComponent,
        ReturnActClientWarehouseSelectComponent,
        ReturnActStatusSelectComponent,
        ReturnActZorderSelectComponent,
        TerminalStatusSelectComponent,
        ReturnActTypeSelectComponent,
        MactStatusSelectComponent,
        NdsTypeSelectComponent,
        TerminalTypeSelectComponent,
        AccountSelectComponent,
        HubSelectComponent,
        ClientCardComponent,
        ClientDialogComponent,
        ClientUsersEditComponent,
        ClientUsersCreateComponent,
        UserCardComponent,
        CourierCardComponent,
        UserDialogComponent,
        BackButtonComponent,
        UserSelectComponent,
        PageNotFoundComponent,
        OrderDialogComponent,
        OrderCardComponent,
        ZorderComponent,
        ZorderModalComponent,
        OrdersMapComponent,
        LocationMapComponent,
        PpActPlacesComponent,
        TerminalCardComponent,
        ReturnActCreateCardComponent,
        CompanyCommissionChairsCardComponent,
        CourierTerminalCardComponent,
        DistrictSelectComponent,
        ZoneSelectComponent,
        StatusSelectComponent,
        LogistMapComponent,
        MainMapComponent,
        InvoicePrintMobileComponent,
        SubstatusSelectComponent,
        RoleCardComponent,
        RoleDialogComponent,
        RightsFailureComponent,
        LabelPrintComponent,
        InvoicePrintComponent,
        HubDeliveryIntervalSelectComponent,
        AddressDialogComponent,
        AddressCardComponent,
        AddressEditorMapComponent,
        AddressEditorMapDialogComponent,
        ChooseDateDialogComponent,
        BarcodePrintComponent,
        KendogridFilterComponent,
        LsMoneyPipe,
        LsNumberPipe,
        LsMoneyBuhPipe,
        UcfirstPipe,
        DocReturnActCardComponent,
        DocReturnActDialogComponent,
        JobsProgressComponent,
        SamedaySelectComponent,
        ShiftSelectComponent,
        ScheduleStatusSelectComponent,
        UniversalEditModalComponent,
        OrderSignatureCardComponent,
        CourierTerminalCardComponent,
        CompanyCommissionChairsCardComponent,
        LogReaderComponent,
        TimerComponent,
        FileUploaderComponent,
        OrderBadgesComponent,
        OrderBadgeItemComponent,
        HubGateModalComponent,

        /** Finance module */
        KassaReportOrdersComponent,
        KassaReportProblemsComponent,
        EKassirEncashmentModalComponent,
        AgentReportCreateComponent,
        AgentReportEditComponent,
        PaymentsReportOrdersComponent,
        PaymentsReportZordersPopupComponent,
        /** /Finance module */

        /** Statistics module */
        CourierSalaryListComponent,
        DailyMeetingOrdersComponent,
        DailyMeetingClientsComponent,
        IntervalsStatDetailsComponent,
        NewStatOrdersComponent,
        /** /Statistics module */

        /** Staff module */
        TerminalCardDialogComponent,
        CourierTerminalCardDialogComponent,
        /** /Staff module */

        /** Logistics module */
        ZoneGroupAddComponent,
        SetCourierArrivedForWorkComponent,
        CodTypeSelectComponent,
        NewBankPaymentOrderComponent,
        NewBankPaymentOrderDialogComponent,
        SpreadBankPaymentOrderComponent,
        SpreadBankPaymentOrderDialogComponent,
        /** /Logistics module */

        /** Storage module */
        InventivActsUploadComponent,
        InventivActsCompareComponent,
        /** /Storage module */
    ],
    exports: [
        SidebarComponent,
        ClientSelectComponent,
        CompanySelectComponent,
        HubSelectComponent,
        ClientCardComponent,
        UserCardComponent,
        TextCopyComponent,
        CourierCardComponent,
        BackButtonComponent,
        OrderCardComponent,
        ZorderComponent,
        UserSelectComponent,
        OrdersMapComponent,
        LocationMapComponent,
        PpActPlacesComponent,
        TerminalCardComponent,
        ReturnActCreateCardComponent,
        CompanyCommissionChairsCardComponent,
        CourierTerminalCardComponent,
        LogistMapComponent,
        DistrictSelectComponent,
        ZoneSelectComponent,
        StatusSelectComponent,
        SubstatusSelectComponent,
        IntervalSelectComponent,
        RoleCardComponent,
        RoleDialogComponent,
        LabelPrintComponent,
        BarcodePrintComponent,
        ChangesLoggerComponent,
        KendogridFilterComponent,
        LsMoneyPipe,
        LsNumberPipe,
        LsMoneyBuhPipe,
        UcfirstPipe,
        DocReturnActCardComponent,
        JobsProgressComponent,
        InvoicePrintComponent,
        CourierTypeSelectComponent,
        IntervalSelectComponent,
        PpActStatusSelectComponent,
        PenaltyAndComplaintSelectComponent,
        ReturnActClientSelectComponent,
        ReturnActReturnReasonSelectComponent,
        ReturnActClientWarehouseSelectComponent,
        ReturnActStatusSelectComponent,
        ReturnActZorderSelectComponent,
        TerminalStatusSelectComponent,
        ReturnActTypeSelectComponent,
        MactStatusSelectComponent,
        NdsTypeSelectComponent,
        TerminalTypeSelectComponent,
        AccountSelectComponent,
        HubDeliveryIntervalSelectComponent,
        SamedaySelectComponent,
        ShiftSelectComponent,
        ScheduleStatusSelectComponent,
        OrderSignatureCardComponent,
        CourierTerminalCardComponent,
        CompanyCommissionChairsCardComponent,

        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatExpansionModule,
        NgxPrintModule,
        DropDownsModule,
        GridModule,
        WindowModule,
        NgxBarcodeModule,
        NgMultiSelectDropDownModule,
        ChartModule,
        MatTooltipModule,
        MatOptionModule,
        MatSelectModule,
        MatBadgeModule,
        EditorModule,
        NgxMatFileInputModule,
        MatCheckboxModule,
        MatCardModule,
        MatListModule,
        MatTabsModule,
        AngularYandexMapsModule,
        TimerComponent,
        FileUploaderComponent,
        OrderBadgesComponent,
        OrderBadgeItemComponent,
        SetCourierArrivedForWorkComponent,
        CodTypeSelectComponent,
        NewBankPaymentOrderComponent,
        NewBankPaymentOrderDialogComponent,
        SpreadBankPaymentOrderComponent,
        SpreadBankPaymentOrderDialogComponent,
    ],
    providers: [
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatExpansionModule,
        {provide: MAT_TABS_CONFIG, useValue: {animationDuration: '0ms'}},
        {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
        {provide: DateAdapter, useClass: CustomDateAdapter,},
    ],
    entryComponents: [
        ChangesLoggerComponent,
        ChangesLoggerModalComponent,
        ClientDialogComponent,
        AddressDialogComponent,
        AddressEditorMapDialogComponent,
        UserDialogComponent,
        ZorderModalComponent,
        OrderDialogComponent,
        RoleCardComponent,
        RoleDialogComponent,
        LabelPrintComponent,
        BarcodePrintComponent,
        DocReturnActDialogComponent,
        ChooseDateDialogComponent,
        UniversalEditModalComponent,
        OrderSignatureCardComponent,
        HubGateModalComponent,

        /** Finance module */
        KassaReportOrdersComponent,
        KassaReportProblemsComponent,
        EKassirEncashmentModalComponent,
        AgentReportCreateComponent,
        AgentReportEditComponent,
        NewBankPaymentOrderComponent,
        NewBankPaymentOrderDialogComponent,
        SpreadBankPaymentOrderComponent,
        SpreadBankPaymentOrderDialogComponent,
        PaymentsReportOrdersComponent,
        PaymentsReportZordersPopupComponent,
        /** /Finance module */

        /** Statistics module */
        CourierSalaryListComponent,
        DailyMeetingOrdersComponent,
        DailyMeetingClientsComponent,
        IntervalsStatDetailsComponent,
        NewStatOrdersComponent,
        /** Statistics module */

        /** Staff module */
        TerminalCardDialogComponent,
        CourierTerminalCardDialogComponent,
        /** /Staff module */

        /** Logistics module */
        ZoneGroupAddComponent,
        /** /Logistics module */

        /** Storage module */
        InventivActsUploadComponent,
        InventivActsCompareComponent,
        /** /Storage module */
    ]
})
export class ComponentsModule {
}
