import {HelpersService} from '../../service/helpers.service';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SkladService} from 'app/service/sklad.service';
import {CounterComponent} from './counter.component';
import {EventerService} from '../../service/eventer.service';
import {CurrentUserService} from '../../service/current-user.service';
import {ShippingQueue, UserHub} from '../../service/models';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';

@Component({
    selector: 'app-board-online',
    templateUrl: './board-online.component.html',
    styleUrls: ['./board-online.component.scss'],
})

export class BoardOnlineComponent implements OnInit, OnDestroy {
    @ViewChild('closeModal') private closeModal;

    public notReadyCouriers = [];
    public index;
    public todayCouriers = [];
    public viewOrder = false;
    public loading = false;
    public selectedHub: UserHub = null;
    private subscr = null;
    public canFinishLoading = false;
    public canAssignLoadingGate = false;
    public loadingFinishLoading = null;

    public loadingHubGatesWithCouriers = null;
    public hubGatesWithCouriers = [];
    public couriersWithoutGates = [];
    public courierToReassignLoadingGates = null;

    public readonly SHIPPING_QUEUE__COURIER_ASSEMBLE = ShippingQueue.COURIER_ASSEMBLE;
    public readonly SHIPPING_QUEUE__COURIER_ASSEMBLED = ShippingQueue.COURIER_ASSEMBLED;
    public readonly SHIPPING_QUEUE__COURIER_LOADING = ShippingQueue.COURIER_LOADING;
    public readonly SHIPPING_QUEUE__COURIER_READY = ShippingQueue.COURIER_READY;

    @ViewChild('counter', {read: CounterComponent}) private counter: CounterComponent;
    counterState = 'counter is ticking';
    public today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');


    constructor(
        private api: SkladService,
        public helpers: HelpersService,
        private eventerService: EventerService,
        private currentUserService: CurrentUserService,
        private datePipe: DatePipe,
        private router: Router,
    ) {
        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.selectedHub = hub;
            this.loadingCourier();
        });

    }


    ngOnInit() {
        this.counter.startAt = 120;
        this.selectedHub = this.currentUserService.getCurrentHub();
        this.canFinishLoading = this.helpers.checkPermissions('storage:courier-queue:finish-loading');
        this.canAssignLoadingGate = this.helpers.checkPermissions('storage:courier-queue:assign-loading-gate');
        this.loadingCourier();
        this.loadHubGatesWithCouriers();

        this.subscr = this.counter.counterState.subscribe(msg => {
            if (msg === 'COMPLETE') {
                this.counterState = 'counter has stopped';
                this.counter.start();
                this.refresh();
            }
        });

        this.counter.start();
    }

    ngOnDestroy() {
        this.counter.stop();
    }

    /**
     * Обработка клика по кнопке обновления табло
     */
    onClickRefresh() {
        this.refresh();
    }

    private refresh() {
        this.loadingCourier();
        this.loadHubGatesWithCouriers();
    }

    private resolveQueueStatusGroup(qs) {
        switch (qs) {
            case ShippingQueue.COURIER_WAIT:
                return 1;

            case ShippingQueue.COURIER_ASSEMBLE:
            case ShippingQueue.COURIER_ASSEMBLED:
            case ShippingQueue.COURIER_LOADING:
                return 2;

            case ShippingQueue.COURIER_READY:
                return 3;

            default:
                return 4;

        }
    }

    loadingCourier() {
        this.loading = true;
        this.api.getTodayCouriers(this.selectedHub.id).subscribe(data => {
            this.todayCouriers = data.todayCouriers.map(courier => {
                courier._sort = 100000 * this.resolveQueueStatusGroup(courier.queue_status || 9) + (courier.priority || 0);
                courier._route_list_url = this.getRouteListLink(courier);
                courier._is_print_route_list_available = this.isPrintRouteListAvailable(courier);
                courier._is_print_material_act_available = this.isPrintCourierActAvailable(courier);
                courier._material_act_url = this.getCourierMaterialActLink(courier);
                return courier;
            }).sort((a, b) => {
                return a._sort - b._sort;
            });

            this.notReadyCouriers = data.notReadyCouriers;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    checkStatus(id) {
        if (id === this.index) {
            this.viewOrder = !this.viewOrder;
        } else {
            this.index = id;
            this.viewOrder = true;
            return this.index;
        }

    }

    /**
     * Обработка клика по кнопке отметки завершения погрузки курьера
     * @param courierUserId
     */
    onClickFinishLoading(courierUserId) {
        this.finishLoading(courierUserId);
    }

    onClickBeginLoading(courierUserId) {
        this.beginLoading(courierUserId);
    }

    /**
     * Отметка завершения погрузки курьера
     * @param courierUserId
     * @private
     */
    private finishLoading(courierUserId) {
        if (this.loadingFinishLoading) {
            return;
        }

        this.loadingFinishLoading = courierUserId;
        this.api.finishCourierLoading({userId: courierUserId}).subscribe(() => {
            this.refresh();
            this.loadingFinishLoading = null;
        }, () => {
            this.loadingFinishLoading = null;
        });
    }

    /**
     * Отметка начала погрузки курьера
     * @param courierUserId
     * @private
     */
    private beginLoading(courierUserId) {
        if (this.loadingFinishLoading) {
            return;
        }

        this.loadingFinishLoading = courierUserId;
        this.api.beginCourierLoading({userId: courierUserId}).subscribe(() => {
            this.loadingCourier();
            this.loadingFinishLoading = null;
        }, () => {
            this.loadingFinishLoading = null;
        });
    }

    /**
     * Загрузка распределения курьеров по воротам
     */
    loadHubGatesWithCouriers() {
        this.loadingHubGatesWithCouriers = true;
        this.api.listWithCouriersOffsets().subscribe(data => {
            this.hubGatesWithCouriers = data.with_gates;
            this.couriersWithoutGates = data.without_gates;

            this.loadingHubGatesWithCouriers = false;
        }, () => {
            this.loadingHubGatesWithCouriers = false;
        });
    }

    /**
     * Обработка клика по кнопке открытия диалога перемещения курьера между воротами погрузки
     * @param courier
     */
    public onClickOpenAssignLoadingGateModal(courier) {
        this.courierToReassignLoadingGates = courier;
    }

    /**
     * Обработка клика по кнопке закрытия диалога перемещения курьера между воротами погрузки
     */
    onClickCloseAssignLoadingGateModal() {
        this.courierToReassignLoadingGates = null;
    }

    private closeAssignLoadingGateModal() {
        this.closeModal.nativeElement.click();
    }

    /**
     * Обработка клика по кнопке назначения курьера на ворота погрузки
     * @param gate
     */
    public onClickAssignLoadingGate(gate) {
        this.loading = true;
        this.api.assignLoadingGate(this.courierToReassignLoadingGates.shipping_queue_id, gate.id).subscribe(() => {
            this.loading = false;
            this.closeAssignLoadingGateModal();
            this.refresh();
        }, () => {
            this.loading = false;
            this.refresh();
        });
    }

    isAssignLoadingGateAvailable(courier) {
        return this.canAssignLoadingGate && (
            courier.queue_status !== this.SHIPPING_QUEUE__COURIER_LOADING &&
            courier.queue_status !== this.SHIPPING_QUEUE__COURIER_READY
        );
    }

    goToLinkIfAvailable(url: string, available: boolean) {
        if (!available) {
            return;
        }

        window.open(url, '_blank');
    }

    private getRouteListLink(courier, shiftNumber = 1) {
        return this.router.createUrlTree([
            '/couriers/route-list/',
            {
                'date': this.today,
                'courier_id': courier.id,
                'shift_number': shiftNumber,
            },
        ]).toString();
    }

    private isPrintRouteListAvailable(courier) {
        return -1 !== [
            ShippingQueue.COURIER_ASSEMBLE,
            ShippingQueue.COURIER_ASSEMBLED,
            ShippingQueue.COURIER_LOADING,
            ShippingQueue.COURIER_READY,
        ].indexOf(courier.queue_status);
    }

    private isPrintCourierActAvailable(courier) {
        return courier.courier_act_id !== null;
    }

    private getCourierMaterialActLink(courier) {
        return this.router.createUrlTree([
            '/print-courier-mact/',
            {
                'mactId': courier.courier_act_id,
            },
        ]).toString();
    }
}
