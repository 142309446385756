import {Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ClientInfo} from '../../service/models';
import {LaraService} from '../../service/lara.service';
import {AutoCompleteComponent} from '@progress/kendo-angular-dropdowns';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {EventerService} from '../../service/eventer.service';
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'app-client-select',
    templateUrl: './client-select.component.html',
    styleUrls: ['./client-select.component.scss']
})
/* дока: https://github.com/nileshpatel17/ng-multiselect-dropdown#readme */
export class ClientSelectComponent implements OnInit, OnDestroy {
    @ViewChild('autocomplete') public autocomplete: AutoCompleteComponent;
    @Input() public selectedClients = [];
    @Input() public selectedClient: ClientInfo = null;

    public choosedClients: ClientInfo[] = [];

    @Output() onChange = new EventEmitter<ClientInfo | ClientInfo[]>();
    @Input() protected multi: boolean = false;
    @Input() protected disabled: boolean = false;
    @ViewChild('td_empty') td: ElementRef;
    public settings: IDropdownSettings = {};

    public clientList: Array<ClientInfo> = [];

    private eventsSubscription: Subscription;
    @Input() events: Observable<any>;


    static getFullName(item: ClientInfo) {
        return '[' + item.client_uid_old + '] ' + item.name;
    }

    constructor(
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private api: LaraService,
        private eventer: EventerService
    ) {
        this.eventer.updateClientList.subscribe(() => {
            this.getClientList();
        })
    }

    protected findModel(id, searchByOldUid = false) {
        const list = this.clientList.filter((item: ClientInfo) => {
            return (!searchByOldUid ? item.client_uid : item.client_uid_old) === id;
        });
        if (!list.length) {
            return;
        }
        return list[0];
    }

    ngOnInit() {
        this.settings = {
            idField: 'client_uid',
            textField: 'full_name_uid',
            allowSearchFilter: true,
            singleSelection: !this.multi,
            enableCheckAll: this.multi,
            itemsShowLimit: 1,
            closeDropDownOnSelection: !this.multi,
            searchPlaceholderText: 'искать',
            noDataAvailablePlaceholderText: 'ничего не найдено',
            defaultOpen: false,
            selectAllText: "Выбрать все",
            unSelectAllText: "Снять все",
        };


        /* грузим список клиентов */
        const clients = this.storage.get('ClientList');

        if (clients) {
            if ((new Date().getTime() - clients.timestamp) / 1000 / 60 / 60 >= 1) {
                this.getClientList();
            }
            this.clientList = clients.data;
        } else {
            this.getClientList();
        }
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => this.onEventSelectValue(data));
        }
    }

    getClientList() {
        this.api.getClientListForSelector().subscribe((data: ClientInfo[]) => {
            data.map(item => {
                item.full_name_uid = ClientSelectComponent.getFullName(item)
            });
            this.clientList = data;
            this.storage.set('ClientList', {timestamp: new Date().getTime(), data: this.clientList});
        });
    }

    onChangeClient() {
        this.onChange.emit(this.multi ? this.choosedClients : this.choosedClients[0]);
    }

    onSelectItem(event) {
        const client: ClientInfo = this.findModel(event.client_uid);

        if (this.multi) {
            this.selectedClients.push(client);
            this.choosedClients.push(client);
        } else {
            this.selectedClient = client;
            this.choosedClients = [client];
        }

        this.onChangeClient();
    }

    onSelectAll() {
        this.selectedClients = this.clientList;
        this.choosedClients = this.clientList.slice();
        this.onChangeClient();
    }

    onDeSelect(event) {
        const client: ClientInfo = this.findModel(event.client_uid);
        if (this.multi) {
            this.selectedClients = this.selectedClients.filter((item: ClientInfo) => {
                return item.client_uid !== client.client_uid;
            });

            this.choosedClients = this.choosedClients.filter((item: ClientInfo) => {
                return item.client_uid !== client.client_uid;
            });
        } else {
            this.selectedClient = null;

            this.choosedClients = [];
        }
        this.onChangeClient();
    }

    onDeSelectAll() {
        this.selectedClients = [];
        this.selectedClient = null;

        this.choosedClients = [];
    }

    keyDown(event) {
        if (event.keyCode === 13) {
            if (!this.multi) {
                this.selectedClient = null;
                this.selectedClients = [];

                this.choosedClients = [];
            }
            this.setData(event);
            this.closeClientList();
            return;
        }
        if (event.keyCode !== 16) {
            return;
        }
        this.setData(event);
    }

    setData(event) {
        if (!event.target && event.target.ariaLabel !== 'multiselect-search') {
            return;
        }
        const client: ClientInfo = this.findModel('LS' + event.target.value, true);
        if (!client) {
            return
        }

        const inList = !!this.choosedClients.slice().find((item: ClientInfo) => {
            return item.client_uid === client.client_uid;
        });

        this.selectedClients = this.selectedClients.filter((item: ClientInfo) => {
            return item.client_uid !== client.client_uid;
        });

        if (!inList) {
            this.selectedClients.push(client);
            this.selectedClient = client;

            if (!this.multi) {
                this.choosedClients = [];
            }
            this.choosedClients.push(client);
        }
        this.onChangeClient();
    }

    closeClientList() {
        this.td.nativeElement.click();
    }

    ngOnDestroy() {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }

    onEventSelectValue(clientIds) {
        this.selectedClients = this.clientList.filter(x => {
            return clientIds.indexOf(x.id) !== -1
        });

        if (!this.multi && this.selectedClients.length === 1) {
            this.selectedClient = this.selectedClients[0];
        }
    }
}
