/**
 * Описание моделей данных
 */
import {CoordsHelper} from './helpers/coords.helper';

export class BaseModel {
    public id?: number;
    public created_at: Date;
    public updated_at: Date;
    public deleted_at?: string | Date;

    constructor(data: any) {
        if (!data) {
            return;
        }
        for (const key of Object.keys(data)) {
            this[key] = data[key];
        }
    }
}

export interface IBaseRecordStatus {
    id: number;
    name: string;
    classes: string;
    _special?: boolean;
}

export class OrdersRequest {
    public statuses?: number[];
    public client_uids?: string[];
    public client_id?: string[];
    public courier_ids?: number[];
    public intervals?: number[];
    public district_ids?: number[];
    public zone_ids?: number[];
    public zone_id?: number;
    public is_important?: boolean;

    public courier_null?: boolean;

    public delivery?: { from: string, to?: string };
    public zones?: { from: number, to?: number };
    public shelfs: { from: number, to?: number };
    public weight?: { from: number, to?: number };

    public sort?: { [key: string]: string };

    public limit?: number | string;
    public page?: number;
    public map?: boolean;

    public hub_id?: number;

    public date_mode?: string;
    public date?: any | { from: string, to?: string };
    public delivery_date?: { from: string, to?: string };
    public created_at?: { from: string, to?: string };
    public stock_datetime?: { from: string, to?: string };

    public current_hubs?: string[];
    public unresolvedProblems?: boolean;
}

export class ClientInfo extends BaseModel {

    /** @var int Агентский договор */
    public static readonly CONTRACT_TYPE_AGENT = 1;
    /** @var int Субагентский договор */
    public static readonly CONTRACT_TYPE_SUBAGENT = 1;

    public static readonly INVENTIV_IDS = [
        2, // TODO айдишники инвентива
    ];

    public static readonly SPORTMASTER_CLIENT_IDS = [
        137,
    ];

    public matrix: string;
    public matrix_spb: string;
    public matrix_top_delivery: string;

    public contract_type: number;
    public kgt_type: string;
    public tax_type: number;
    public uid: string;
    public name: string;
    public client_uid: string;
    public client_uid_old: string;
    public full_name: string;
    public doc_num: string;
    public doc_date: Date;
    public inn: string;
    public kpp: string;
    public ogrn: string;
    public bank_korr: string;
    public bank_raschet: string;
    public bank_name: string;
    public bank_bik: string;
    public jur_address: string;
    public ceo: string;
    public type: string;
    public parent_company: string;
    public parent_id: number;
    public active: boolean;
    public is_places: boolean;
    public is_round_weight: boolean;
    public api_key: string;
    public regional_agreement: boolean;
    public is_intermediary: boolean;
    public is_recipient_required: boolean;
    public parking_payment: number;
    public cash_only: boolean;
    public is_readonly: boolean;
    public set_refused_orders_unopened: boolean;
    public is_wc_delivery: boolean;
    public repeat_delivery_payment: boolean;
    public redelivery_percent: number;
    public is_regions_by_easy_bulky: boolean;
    public is_ignore_kgt: boolean;
    public is_calc_spb_by_km: boolean;
    /* кастомное свойство для автокомплита */
    public full_name_uid: string;
    public default_hub_id: number;
    public default_hub: Hub;
    public force_orange_receipts_since: string;
    public cabinets: ClientUser[];
    public is_sameday_possible: boolean;
    public is_sameday_take_by_cargo: boolean;

    public bookkeeper_service_phone: string;
    public bookkeeper_service_email: string;
    public logistics_phone: string;
    public logistics_email: string;
    public returns_email: string;
    public returns_phone: string;
    public customer_service_phone: string;
    public customer_service_email: string;
    public sales_phone: string;
    public sales_email: string;
    public contact_email: string;
    public finance_email: string;
    public contact_number: string;
    public callcenter_phone: string;
    public contact_name: string;
    public website: string;
    public notes: string;

    public sales_manager_uid: string;
    public sales_manager_name: string;
    public account_manager_uid: string;
    public account_manager_name: string;
    public connection_manager_uid: string;
    public connection_manager_name: string;

    public manager: User;

    public settings?: ClientSetting[];
    public company?: ParentCompany;

    public files?: any
}

export class ClientUser extends BaseModel {
    public id: number;
    public client_id: number;
    public email: string;
    public phone: string;
    public login: string;
    public name: string;
    public position: string;
    public password: string;
    public is_old_password: number;
    public last_login: Date;
}

export class MatrixCategoryResponse {
    public moscow: [];
    public spb: string[];
    public top_delivery: string[];
}

export class MatrixCategory extends BaseModel {
    public matrix_type: string;
    public name: string;
    public client_id: number;
    public is_system: number;
    public is_offer: number;
    public is_volume_based: number;
    public min_orders_count: number;
}

export class ApiKey extends BaseModel {
    public apikey: string;
    public active: boolean;
}

export class UserGroup extends BaseModel {
    public id: number;
    public title: string;

}

export class User extends BaseModel {
    /* дефолтные группы пользователей */
    public ROLE_ADMIN = 1;
    public ROLE_DIRECTOR = 2; /* Руководтели */
    public ROLE_MANAGER = 3; /* Менеджеры по продажам */
    public ROLE_COURIER = 4; /* Курьеры */
    public ROLE_LOGISTIC = 5; /* Логистика */
    public ROLE_FINANCE = 6; /* Финансовый отдел */
    public ROLE_PROGRAMMER = 7; /* Программисты */
    public ROLE_STOCK = 8; /* Склад */
    public ROLE_SERVICE = 9; /* Клиентский сервис */
    public ROLE_CASHBOX = 10; /* Касса */
    public ROLE_CUSTOMER = 11; /* Клиенты */

    public name: string;
    public email: string;
    public client_id?: number;
    public active: boolean;
    public blocked: boolean;
    public role: number;
    public last_request?: Date;
    public last_login?: Date;
    public phone_extension?: string;
    public default_hub_id: number;
    public date_of_birth?: Date;

    public hubs: UserHub[];
    public default_hub: UserHub;
    public courier_option?: CourierOption;

    public roles: any;
    public permissions: any;
    public groups: { id: number; title: string }[];

    public work_is_first_shift: number;
    public work_is_second_shift: number;

    public getRoleString() {
        return this.getRoleTitle()[this.role - 1];
    }

    public getRoleTitle() {
        return ['Admin', 'Руководитель', 'Менеджер по продажам', 'Курьер', 'Логист', 'Финансовый отдел', 'Программист', 'Склад', 'Клиентский сервис', 'Касса', 'Клиент'];
    }


    /**
     * @param role
     * @return boolean
     */

    /*public isRole(role) {
        return this.role === role;
    }*/
    public isRole(set_role) {
        for (const role of this.roles) {
            if (role.id === set_role) {
                return true;
            }
        }
    }

    /**
     * @return boolean
     */
    public isRoleAdmin() {
        return this.isRole(this.ROLE_ADMIN);
    }

    public isRoleDirector() {
        return this.isRole(this.ROLE_DIRECTOR);
    }

    public isRoleManager() {
        return this.isRole(this.ROLE_MANAGER);
    }

    public isRoleCourier() {
        return this.isRole(this.ROLE_COURIER);
    }

    public isRoleLogistic() {
        return this.isRole(this.ROLE_LOGISTIC);
    }

    public isRoleFinance() {
        return this.isRole(this.ROLE_FINANCE);
    }

    public isRoleStock() {
        return this.isRole(this.ROLE_STOCK);
    }

    public isRoleProgrammer() {
        return this.isRole(this.ROLE_PROGRAMMER);
    }

    public isRoleService() {
        return this.isRole(this.ROLE_SERVICE);
    }

    public isRoleCashBox() {
        return this.isRole(this.ROLE_CASHBOX);
    }

    public isRoleCustomer() {
        return this.isRole(this.ROLE_CUSTOMER);
    }

    public inGroup(id) {
        return this.groups.filter(item => item.id === id).length > 0;
    }

    public inPermission(name) {
        console.log(this);
        for (const user_permission of this.permissions) {
            if (name === user_permission.name) {
                return true;
            }
        }
        return false;
    }

    public inGroups(ids: number[]) {
        return this.groups.filter(item => ids.indexOf(item.id) > 0).length;
    }

    public hasHub(id: number) {
        console.log(this.hubs);
        return !!this.hubs.find((hub: UserHub) => hub.id === id);
    }
}

export class MatrixRegion extends BaseModel {
    public code: number;
    public title: string;
    public is_dist: boolean;
    public opt_sms: number;
    public opt_returned_doc: number;
    public opt_opening: number;
    public opt_buyback: number;
    public opt_fitting: number;
    public opt_skid_kgt: number;
    public opt_call: number;
    public opt_equipment: number;
    public opt_packaging: number;
}

export class MatrixData {
    public static readonly OPTIONS = [
        {key: 'return_tar_type', label: 'Тарификация част. отказ'},
        {key: 'buyback', label: 'Возврат част. отказ(%)'},
        {key: 'call', label: 'Прозвон клиента (за день до доставки)'},
        {key: 'equipment', label: 'Комплектация'},
        {key: 'fitting', label: 'Проверка заказа/проверка электроники'},
        {key: 'opening', label: 'Вскрытие заказа'},
        {key: 'packaging', label: 'Упаковка'},
        {key: 'returned_doc', label: 'Возврат накладной'},
        {key: 'sms', label: 'Стоимость СМС'},
        {key: 'sms_type', label: 'Оплата СМС'},
        {key: 'cod_cash', label: 'Наложенный платеж (нал) %'},
        {key: 'cod_card', label: 'Наложенный платеж (безнал) %'},
        {key: 'cod_sbp', label: 'Наложенный платеж (СБП) %'},
        {key: 'os', label: 'Объявленная ценность %'},
        {key: 'minimal_os', label: 'Мин. объявл. ценность (руб)'},
        {key: 'return_price', label: 'Стоимость возврата'},
        {key: 'return_only_rate', label: 'Коэф (клиентский возврат)'},
        {key: 'return_only_fix', label: 'Фикс (клиентский возврат)'},
        {key: 'return_combined_rate', label: 'Коэф (обмен)'},
        {key: 'return_combined_fix', label: 'Фикс (обмен)'},
        {key: 'installation_service', label: 'Услуги монтажа (руб)'},
        {key: 'underground_parking', label: 'Подземный паркинг (руб)'},
    ];

    /* массив дистанций */
    public dist: number[];
    /* массив дистанций */
    public mass: number[];
    /*
    сама матрица
    matrix[mass][dist] = [price, price_delta, is_edit]
    */
    public matrix: number[];
    /* массив платных опций */
    public options: { string: number };
    /* массив платных опций с ценами по умолчанию */
    public options_default: { string: number };
}

export class MatrixKgt {
    public mass: number[];
    public matrix: any;
}

export class MatrixTakeAway {
    public dist_from: number;
    public dist_to: number;
    public price: number;
}

export class MatrixTakeAwayCfo {
    public zone_group_id: number;
    public price: number;
}

export class SubAgent extends BaseModel {
    public old_id: number;
    public name: string;
    public name_full: string;
    public contact_name: string;
    public contact_number: string;
    public contact_mail: string;
    public contract: string;
    public contract_date: string;
    public director: string;
    public type: number;
    public parent_company: number;
    public pvz_insurance: number;
    public pvz_cod_cash: number;
    public pvz_cod_card: number;
    public pvz_sms: number;
    public pvz_storage: number;
    public pvzs: Pvz[];
}

export class Pvz extends BaseModel {
    public old_id: number;
    public sub_agent_id: number;
    public name: string;
    public contract: string;
    public contract_date: Date;
    public address_id: number;
    public address: Address;
}

export class ShiftType {
    public static readonly FIRST_SHIFT = 1;
    public static readonly SECOND_SHIFT = 2;
    public static readonly description = [
        {name: '1 Смена', value: 1},
        {name: '2 Смена', value: 2},
    ];
}

export class Problem {
    public static TYPE_RECIPIENT_NOT_IN_PLACE = 7;
}

export class OrderStorageData extends BaseModel {
    order_id: number;
    order_uid?: string;
    original_delivery_date?: Date | string;
    original_delivery_time_start?: number;
    original_delivery_time_end?: number;
    hub_destination_arrival?: number | string;
    original_np?: number;
    current_np?: number;
    changed_at?: Date | string;
    expected_return_time?: Date | string;
    close_latitude?: number;
    close_longitude?: number;
    client_fields_json?: string | object;
    source?: number;
    is_visible_for_api?: number;

    source_name?: string;
}

/********************************************************
 * Модель заказа
 */
export class Order extends BaseModel {
    // Новая заявка
    public static STATUS_NEW = 1;
    // Заявка принята
    public static STATUS_ACCEPTED = 2;
    // На складе
    public static STATUS_STORAGE = 3;
    // На доставке
    public static STATUS_DELIVERY = 4;
    // Доставлена
    public static STATUS_FINISH = 5;
    // Част. отказ
    public static STATUS_FAIL_PARTIAL = 6;
    // Полн. отказ
    public static STATUS_REFUSE = 7;
    // Отмена
    public static STATUS_CANCEL = 8;
    // Перемещение между хабами
    public static STATUS_TRANSIT = 9;
    // Отмена для возврата
    public static STATUS_CANCEL_FOR_RETURN = 10;

    public static STATUSES_DESCRIPTION = [
        {'id': Order.STATUS_NEW, name: 'Новая заявка'},
        {'id': Order.STATUS_ACCEPTED, name: 'Заявка принята'},
        {'id': Order.STATUS_STORAGE, name: 'На складе'},
        {'id': Order.STATUS_DELIVERY, name: 'На доставке'},
        {'id': Order.STATUS_FINISH, name: 'Доставлена'},
        {'id': Order.STATUS_FAIL_PARTIAL, name: 'Част. отказ'},
        {'id': Order.STATUS_REFUSE, name: 'Полн. отказ'},
        {'id': Order.STATUS_CANCEL, name: 'Отмена'},
        {'id': Order.STATUS_TRANSIT, name: 'Транзит'},
        {'id': Order.STATUS_CANCEL_FOR_RETURN, name: 'Отмена для возврата'},
    ];

    public static STATUSES_CLOSED = [Order.STATUS_REFUSE, Order.STATUS_FAIL_PARTIAL, Order.STATUS_FINISH, Order.STATUS_CANCEL, Order.STATUS_CANCEL_FOR_RETURN];

    /** @var int Нет клиентских возвратов */
    public static readonly CLIENT_RETURN_NONE = 0;
    /** @var int Есть и товары для доставки, и товары для возврата */
    public static readonly CLIENT_RETURN_COMBINED = 1;
    /** @var int Только товары для возврата */
    public static readonly CLIENT_RETURN_ONLY = 2;

    /** @var int Нет услуг монтажа */
    public static readonly INSTALLATION_SERVICE_NOT_DECLARED = 0;
    /** @var int Услуги монтажа не оказаны */
    public static readonly INSTALLATION_SERVICE_NONE = 1;
    /** @var int Услуги монтажа оказаны частично */
    public static readonly INSTALLATION_SERVICE_PARTIAL = 2;
    /** @var int Услуги монтажа оказаны в полном объёме */
    public static readonly INSTALLATION_SERVICE_FULL = 3;

    // До скольки можно создать заказ sameday
    public static MAX_HOUR_CREATE_SAMEDAY_DELIVERY = 15;

    public id: number;
    public courier_id: number;
    public inner_n: string;
    public delivery_date: string;
    public delivery_time: number;
    public status: number;
    public request: string;
    public created_at: Date;
    public updated_at: Date;
    public uid: string;
    public client_id: number;
    public client_uid: string;
    public barcode: string;
    public been_on_warehouse: number;
    public was_opened: number;
    public hash_bar_codes_count: number;
    public zone_id: number;
    public returned_courier_id: number;
    public returned_storage: number;
    public sync_date: Date;
    public sms_counter: number;
    public substatus_id: number;
    public is_regional: number;

    public bill: OrderBill;
    public option: OrderOption;
    public storage_data?: OrderStorageData;
    public target: OrderTarget;
    public client: ClientInfo;
    public zone: OrderZone;
    public goods: OrderGood[];
    public refuse_goods: OrderGood[];
    public returned_goods: OrderGood[];
    public all_goods: OrderGood[];
    public bar_codes: OrderBarCode[];
    public bar_codes_count?: number;
    public services?: [];

    public log_status: OrderLogStatus[];
    public cab_logs: OrderCabLog[];
    public courier: User;
    public return_courier: User;
    public vendor_code: string;

    public hub_origin_id?: number;
    public hub_destination_id?: number;
    public hub_current_id?: number;

    public hub_origin?: Hub;
    public hub_destination?: Hub;
    public hub_current?: Hub;

    public shift_number?: number;
    public is_sameday?: boolean;
    public is_need_new_label?: boolean;

    public is_client_return?: number;
    public is_important?: any; // TODO: Ganzal: Fix OrderMapPoint.is_important
    public is_installation_service?: number;

    public stock_datetime?: Date | string;
    public storage_expire_date?: Date | string;
    public pickup_point_free_storage_expire_date?: Date | string;

    /**
     * TODO Ganzal: Это для списка заказов в распределении выводится и по факту к модели не имеет отношения.
     *  Надо бы завести отдельный класс для комбинированного описания заказа в ответе списка распределения.
     */
    public option_is_bulky?: number;
    public option_pickup_point_shipment?: number;
    public option_vip_delivery_shipment?: number;
    /**
     * @deprecated
     */
    public orderFiles?: OrderFile[];

    public files?: File[];
}

export class AktNumbers {
    public old_vact_id: string;
}

export class Mact {
    public macId: number;
}

export class OrderBill {
    public payment: number;
    public payment_cash: number;
    public payment_card: number;
    public tariff_total: number;
    public tariff_base: number;
    public tariff_buyback: number;
    public tariff_call: number;
    public tariff_card: number;
    public tariff_cash: number;
    public tariff_sbp_payment: number;
    public tariff_equipment: number;
    public tariff_fitting: number;
    public tariff_opening: number;
    public tariff_os: number;
    public tariff_packaging: number;
    public tariff_returned_doc: number;
    public tariff_skid_kgt: number;
    public tariff_sms: number;
    public calculator_result: number;
    public os: number;
    public is_np: number;
    public np: number;
    public is_calculated_delivery_price: number;

    public price_client_delivery: number;
    public price_client_delivery_nds: number;
    public sms_counter: number;
    public tariff_return: number;
    public card_type: number;
    public cod_cashless_provider: number;
    public real_price_courier: number;
    public calc_price_courier: number;
    public tariff_redelivery: number;
    public tariff_client_return: number;
    public prepaid_amount: number;
    public tariff_extra_places: number;
    public tariff_places_overweight: number;
    public tariff_installation_service: number;
    public tariff_canceled_with_delivery_attempts: number;
    public tariff_pickup_point_shipment: number;
    public tariff_pickup_point_storage_overdue: number;
    public pickup_point_storage_overdue_days: number;
    public tariff_underground_parking: number;
}

export class OrderOption {
    public static readonly PRETENSION_STATUSES_DESCRIPTIONS = [
        {'id': 1, 'name': 'подписан покупателем'},
        {'id': 2, 'name': 'не подписан покупателем'},
        {'id': 3, 'name': 'покупателя не было на месте'},
    ];

    public dimension_side1: number;
    public dimension_side2: number;
    public dimension_side3: number;
    public weight: number;
    public weight_validated: boolean;
    public count: number;
    public option_opening: number;
    public option_sms: number;
    public option_call: number;
    public option_returned_doc: number;
    public option_buyback: number;
    public option_fitting: number;
    public option_skid_kgt: number;
    public option_pickup_point_shipment: number;
    public option_vip_delivery_shipment: number;
    public is_bulky?: boolean;
}

export class OrderTarget {
    public address_text: string;
    public region_code: number;
    public city: string;
    public city_kladr: number;
    public house: number;
    public building: number;
    public office: number;
    public intercom: string;
    public floor: number;
    public lift: boolean;
    public target_name: string;
    public target_contacts: string;
    public target_notes: string;
    public organization_name: string;
    public second_phone: string;
    public mkad_dist: number;
    public lat: number;
    public lon: number;
}

export class OrderGood extends BaseModel {

    /** @var int Ожидается на склад */
    public static readonly STATUS_EXPECTED = 1;

    /** @var int Принят на склад */
    public static readonly STATUS_ADOPTED = 2;

    /** @var int Выдан на доставку */
    public static readonly STATUS_DELIVERY = 3;

    /** @var int Доставлен */
    public static readonly STATUS_FINISH = 4;

    /** @var int Отказной товар */
    public static readonly STATUS_REFUSE = 5;

    /** @var int Выдан с возвратом */
    public static readonly STATUS_RETURN = 6;

    /** @var int Отмена */
    public static readonly STATUS_CANCEL = 7;

    /** @var int Ожидается от Агента */
    public static readonly STATUS_EXPECTED_AGENT = 8;

    /** @var int Утрачен */
    public static readonly STATUS_DAMAGED = 9;

    /** @var int Утрачен и закрыт */
    public static readonly STATUS_DAMAGED_AND_COMPENSATED = 10;

    /** @var int Возвращён клиенту */
    public static readonly STATUS_RETURNED = 11;

    /** @var int Забрать у получателя */
    public static readonly STATUS_COLLECT_FROM_CUSTOMER = 12;

    /** @var int  Отказ от возврата */
    public static readonly STATUS_CUSTOMER_REFUSED_TO_RETURN = 13;

    /** @var int  Забран у получателя */
    public static readonly STATUS_COLLECTED_FROM_CUSTOMER = 14;

    public static readonly STATUSES_DESCRIPTIONS = [
        {'id': 1, 'name': 'Ожидается на склад'},
        {'id': 2, 'name': 'Принят на склад'},
        {'id': 3, 'name': 'Выдан на доставку'},
        {'id': 4, 'name': 'Доставлен'},
        {'id': 5, 'name': 'Отказной товар'},
        {'id': 6, 'name': 'Выдан с возвратом'},
        {'id': 7, 'name': 'Отмена'},
        {'id': 8, 'name': 'Ожидается от Агента'},
        {'id': 9, 'name': 'Утрачен'},
        {'id': 10, 'name': 'Утрачен и закрыт'},
        {'id': 11, 'name': 'Возвращён клиенту'},
        {'id': 12, 'name': 'Забрать у получателя'},
        {'id': 13, 'name': 'Отказ от возврата'},
        {'id': 14, 'name': 'Забран у получателя'},
    ];

    /** @var int Обычный товар */
    public static readonly TYPE_GOOD = 1;

    /** @var int Услуга, например, доставка */
    public static readonly TYPE_SERVICE = 2;

    /** @var int Возвращаемая услуга, например, доставка у БашМаг */
    public static readonly TYPE_RETURNABLE_SERVICE = 3;

    /** @var int Клиентский возврат */
    public static readonly TYPE_CLIENT_RETURN = 4;

    /** @var int Услуги монтажа */
    public static readonly TYPE_INSTALLATION_SERVICE = 5;

    public static readonly TYPES_DESCRIPTIONS = [
        {'id': 1, 'name': 'Товар'},
        {'id': 2, 'name': 'Услуга'},
        {'id': 3, 'name': 'Возвращаемая услуга'},
        {'id': 4, 'name': 'Клиентский возврат'},
        {'id': 5, 'name': 'Услуги монтажа'},
    ];

    bar_code_id: number;
    count: number;
    is_cancel: number;
    name: string;
    nds: number;
    price: number;
    declared_price: number;
    return_act_id: string;
    status: number;
    vendor_code: number;
    weight: number;
}

export class OrderBarCode extends BaseModel {
    /** @var int Ожидается на склад */
    public static readonly STATUS_EXPECTED = 1;

    /** @var int Принят на склад */
    public static readonly STATUS_ADOPTED = 2;

    /** @var int Выдан на доставку */
    public static readonly STATUS_DELIVERY = 3;

    /** @var int В перемещении  */
    public static readonly STATUS_TRANSIT = 4;

    /** @var int Возвращен клиенту  */
    public static readonly STATUS_RETURNED = 5;

    public static readonly STATUSES_DESCRIPTION = [
        {id: 1, description: 'Ожидается на склад'},
        {id: 2, description: 'На складе'},
        {id: 3, description: 'Выдано на доставку'},
        {id: 4, description: 'В перемещении'},
        {id: 5, description: 'Возвращено клиенту'},
    ];

    public order_id: string;
    public order_uid?: string;
    public bar_code: string;
    public is_cancel: boolean;
    public weight: boolean;
    public status: number;
    public place_num: number;
    public warehouse_id?: number;
    public scan_time?: string;
    public return_act_id?: number;
    public dimension_side1?: number;
    public dimension_side2?: number;
    public dimension_side3?: number;
    public is_client_return?: number;
    public is_weight_checked?: number;

}

export class OrderZone {
    public id: number;
    public place: any;
    public zone_id: number;
    public shelf: number;
    public color: string;
    public tarif: number;
    public district: District;
    public district_id: number;
    public geometry: string;
}

export class District {
    public id: number;
    public name: string;
}

export class OrderLogStatus {

}

export class OrderCabLog {
    public o_id: string;
    public who: number;
    public what: string;
    public old_val: string;
    public new_val: string;
    public timestamp: Date;
}

export class OrderSignature extends BaseModel {
    public static readonly ORIGINS = [
        {id: 1, description: 'Загружена курьером'},
        {id: 2, description: 'Скопирована существующая'},
    ];
    public static readonly ORIGIN_COURIER_APP = 1;
    public static readonly ORIGIN_CLONED = 2;

    public order_id: number;
    public order_uid: string;
    public courier_id: number;
    /**
     * @deprecated Use orders_targets.recipient_name
     */
    public recipient_fio?: string;
    public signature?: string;
    public origin: number;
    public is_effective?: number | boolean;
    public is_verified?: number | boolean;
    public verifier_user_id?: number;
    public verified_at?: Date;
    public file_url?: string;
    public relative_url?: string;

    public is_origin_courier_app?: boolean;
    public is_origin_cloned?: boolean;
    public img_src?: string;

    constructor(data: any) {
        super(data);

        this.is_origin_courier_app = (data.origin === OrderSignature.ORIGIN_COURIER_APP);
        this.is_origin_cloned = (data.origin === OrderSignature.ORIGIN_CLONED);

        if (data.relative_url) {
            this.img_src = data.relative_url;
        } else if (data.signature) {
            this.img_src = 'data:image/png;base64,' + data.signature;
        }
    }
}

export class SimilarOrderSignature extends OrderSignature {
    public target_name?: string;
    public recipient_name?: string;
    public suggestion_type?: string;
}

/********************************************************
 * Модели адресов
 */
class BaseAddress {
    public kladr_id: number;
    public fias_id: string;
    public name: string;
    public name_full: string;
    public type: string;
    public type_full: string;
}

export class Region extends BaseAddress {
    public region_code: number;
}

export class City extends BaseAddress {
}

export class Settlement extends BaseAddress {
}

export class Street extends BaseAddress {
}

export class House extends BaseAddress {
    public number: string;
    public block_type: string;
    public block_type_full: string;
    public block: string;
}

export class Qc {
    public qc: number;
    public qc_geo: number;
    public qc_complete: number;
    public qc_house: number;
}

export class Address {
    public id: number;
    public source: string;
    public result: string;
    public validate: boolean;
    public latlon: number[];
    public region_code: number;
    public behind_mkad: boolean;
    public mkad_distance: number;
    public region: Region;
    public city: City;
    public settlement: Settlement;
    public street: Street;
    public house: House;
    public qc: Qc
}

export class Courier {
    public allowAssembly: string;
    public user: [
        { id: string },
        { name: string }
    ];

    error: string;
}

export class CourierList {
    public id: number;
    public name: string;
    public email: string;
    public client_id: string;
    public active: number;
    public blocked: number;
    public role: number;
    public courier_option: CourierOption;
}

export class CourierOption extends BaseModel {
    public old_courier_id: number;
    public user_id: number;
    public full_name: string;
    public phone?: string;
    public corporate_phone?: string;
    public mobile_phone1?: string;
    public mobile_phone2?: string;
    public car_mark: string;
    public car_model: string;
    public car_number: string;
    public car_volume_capacity: number;
    public car_weight_capacity: number;
    public car_is_downtown_permitted: number;
    public fuel_grade_id: number;
    public fuel_consumption: number;
    public printer_number: number;
    public terminal_serial: string;
    public device_token?: string;
    public is_ios: number;
    public type: number;
    public company_id?: number;
    public avatar?: string;
    public pass_sn?: string;
    public pass_kem?: string;
    public pass_date?: string | Date;
    public pass_kod?: string;
    public pass_address?: string;
    public fact_address?: string;
    public driver_license?: string;
    public ekashier_allowed: boolean;
    public is_self_employed: boolean;
    public cargo_tariff_id?: number;
    public login?: string;
    public shift_without_call?: number;
    public special_type?: number;
    public work_is_first_shift: number;
    public work_is_second_shift: number;
    public district_id?: number;
    public work_is_all_directions?: number;
    public terminal_type: number;
    public master_terminal_id?: number;
    public is_close_with_locations: boolean;
    public is_last_on_queue: boolean;
    public is_far: boolean;
    public is_test: boolean;

    public terminal?: Terminals;
    public terminals?: Terminals[];

    public user?: User[];
}

export class OrdersList {
    public id: string;
    public uid: string;
    public inner_n: string;
    public barcode: string;
}


export class ZorderList {
    public id: number;
    public old_zorder_id: number;
    public client_id: string;
    public courier_id: number;
    public inner_n: string;
    public date: string;
    public is_sameday: boolean | number;
    public time_begin: string;
    public time_end: string;
    public warehouse: any;
    public warehouse_id: number;
    public zone_id: number;
    public comments: string;
    public status: number;
    public price: number;
    public bill_id_1c: string;
    public weight: number;
    public capacity: number;
    public car_type: number;
    public places_count: number;
    public link_bill_id_1c: number;
    public sync_date: string;
    public mo_mkad: number;
    public real_price_courier: number;
    public calc_price_courier: number;
    public substatus: number;
    public client: ClientInfo;
    public zone: OrderZone;

}

export class Tax {
    // HДС 18%
    public static readonly TAX_NDS_18        = 1;
    // Без HДС
    public static readonly TAX_WITHOUT_NDS   = 2;
    // HДС 10%
    public static readonly TAX_NDS_10        = 3;
    // HДС 18/118
    public static readonly TAX_NDS_18_118    = 4;
    // HДС 10/110
    public static readonly TAX_NDS_10_110    = 5;
    // НДС 0%
    public static readonly TAX_NDS_0         = 6;
    // НДС 20%
    public static readonly TAX_NDS_20        = 7;
    // НДС 20/120
    public static readonly TAX_NDS_20_120    = 8;
    // НДС 5%
    public static readonly TAX_NDS_5 = 9;

    // НДС 7%
    public static readonly TAX_NDS_7 = 10;

    // НДС 5/105
    public static readonly TAX_NDS_5_105 = 11;

    // НДС 7/107
    public static readonly TAX_NDS_7_107 = 12;


    public static TYPES_DESCRIPTION = [
        {'value': Tax.TAX_NDS_18, 'name': 'HДС 18%'},
        {'value': Tax.TAX_WITHOUT_NDS, 'name': 'Без HДС'},
        {'value': Tax.TAX_NDS_10, 'name': 'HДС 10%'},
        {'value': Tax.TAX_NDS_18_118, 'name': 'HДС 18/118%'},
        {'value': Tax.TAX_NDS_10_110, 'name': 'HДС 10/110%'},
        {'value': Tax.TAX_NDS_0, 'name': 'НДС 0%'},
        {'value': Tax.TAX_NDS_20, 'name': 'НДС 20%'},
        {'value': Tax.TAX_NDS_20_120, 'name': 'НДС 20/120%'},
        {'value': Tax.TAX_NDS_5, 'name': 'НДС 5%'},
        {'value': Tax.TAX_NDS_7, 'name': 'НДС 7%'},
        {'value': Tax.TAX_NDS_5_105, 'name': 'НДС 5/105%'},
        {'value': Tax.TAX_NDS_7_107, 'name': 'НДС 7/107%'},
    ];
}

export class Company {
    public id: number;
    public created_at: string;
    public updated_at: string;
    public name: string;
    public full_name: string;
    public inn: number;
    public ogrn: number;
    public kpp: number;
    public ur_address: string;
    public fact_address: string;
    public bank_name: string;
    public bank_bik: number;
    public bank_corr: number;
    public bank_account: number;
    public dir_fio: string;
    public dir_fio_rod: string;
    public dir_fio_short: string;
    public buh_fio: string;
    public buh_fio_rod: string;
    public buh_fio_short: string;
    public tax_type: string;
    public tax_type_print: string;
}

export class barCodes {
    public barcode: string;
}

export class CourierValid {
    name: string;
    email: string;
    phone: string;
}

export class Packs {
    public id: number;
    public status: number;
    public orders?: Order[];
    public transit_id: number;
}

export class Hub extends BaseModel {
    // Хаб Москва
    public static readonly HUB_MOSCOW = 1;

    // Хабы Логсис
    public static readonly TYPE_LOGSIS = 1;
    // Хабы ТопДеливери
    public static readonly TYPE_TOPDELIVERY = 2;

    public name: string;
    public full_name_id: string;
    public map_zoom: number;
    public map_center_lat: number;
    public map_center_lon: number;
    public delivery_intervals?: HubDeliveryInterval[];
    public default_delivery_interval?: HubDeliveryInterval;
    public type: number;

    constructor(data: any) {
        super(data);

        this.full_name_id = this.name + ' [' + this.id + ']';

        if (data.hasOwnProperty('delivery_intervals') && data.delivery_intervals.length) {
            this.delivery_intervals = data.delivery_intervals.map(interval => {
                return new HubDeliveryInterval(interval);
            });

            this.default_delivery_interval = this.delivery_intervals.find((interval: HubDeliveryInterval) => {
                return interval.is_default_interval;
            });
        }
    }
}

export interface IHubGateRecordStatus extends IBaseRecordStatus {
};

export class HubGate extends BaseModel {
    /** @var int В работе */
    public static readonly STATUS_NORMAL = 1;

    /** @var int В резерве */
    public static readonly STATUS_RESERVED = 2;

    /** @var int Отключены */
    public static readonly STATUS_DISABLED = 3;

    /**
     * Возможные статусы ворот
     */
    public static readonly STATUSES: IHubGateRecordStatus[] = [
        {id: HubGate.STATUS_NORMAL, name: 'В работе', classes: 'badge badge-success'},
        {id: HubGate.STATUS_RESERVED, name: 'В резерве', classes: 'badge badge-warning'},
        {id: HubGate.STATUS_DISABLED, name: 'Отключены', classes: 'badge badge-dark'},
    ];

    public hub_id: number;
    public hub: Hub;
    public num: number;
    public tip?: string;
    public lat?: number;
    public lon?: number;
    public assembling_capacity?: number;
    public loading_capacity?: number;
    public status: number;

    public is_capacities_valid?: boolean;

    public static isCapacitiesValid(assemblingCapacity, loadingCapacity) {
        if (null === assemblingCapacity || assemblingCapacity <= 0) {
            assemblingCapacity = Number.MAX_VALUE;
        }

        if (null === loadingCapacity || loadingCapacity <= 0) {
            loadingCapacity = Number.MAX_VALUE;
        }

        return (loadingCapacity === Number.MAX_VALUE || loadingCapacity <= assemblingCapacity);
    }

    constructor(data: any) {
        super(data);

        this.is_capacities_valid = HubGate.isCapacitiesValid(this.assembling_capacity, this.loading_capacity);
    }
}

export class ZoneGroup extends BaseModel {
    public hub_id: number;
    public hub: Hub;
    public name: string;
}

export class UserHub extends Hub {
    public is_default_hub: boolean;
    public pivot: {
        hub_id: number;
        is_default_hub: boolean;
        user_id: number;
    };

    constructor(data: any) {
        super(data);

        this.is_default_hub = this.pivot.is_default_hub = !!this.pivot.is_default_hub;
    }
}

export class HubDeliveryInterval extends BaseModel {
    public static NOT_PRESENT_INTERVAL_ID = 6;

    id: number;
    hub_id: number;
    time_start: string;
    time_end: string;
    hours: [
        (number | string),
        (number | string)
    ];
    hours_full: [
        (string),
        (string)
    ];
    is_default_interval?: boolean;
    shift_number: number;
    label: string;
    label_short: string;

    constructor(data: any) {
        super(data);

        this.is_default_interval = data.hasOwnProperty('is_default_interval') ? !!this.is_default_interval : false;

        if (data.id === HubDeliveryInterval.NOT_PRESENT_INTERVAL_ID) {
            this.label = 'Не передали интервал';
            this.label_short = 'Нет интервала';
        }
    }
}

export class Pallet extends BaseModel {
    public static TYPE_TRANSIT = 1;
    public static TYPE_RETURN = 2;

    public static TYPES_DESCRIPTION = [
        {'id': 1, 'name': 'Перемещение'},
        {'id': 2, 'name': 'Возврат'},
    ];
    public static STATUSES_DESCRIPTION = [
        {'id': 1, 'name': 'Новая'},
        {'id': 2, 'name': 'На доставке'},
        {'id': 3, 'name': 'Доставлена'},
    ];

    public type: number;
    public status: number;
    public hub_origin?: Hub;
    public hub_origin_id?: number;
    public hub_destination?: Hub;
    public hub_destination_id?: number;
    public transit_id?: number;
    public places?: any;
    public transit?: Transit;
}

export class CourierExtraExpences extends BaseModel {


    public static TYPES_DESCRIPTION = [
        {'id': 1, 'name': 'Парковка'},
        {'id': 2, 'name': 'Другое'},
        {'id': 3, 'name': 'Парковка в БЦ'},
        {'id': 4, 'name': 'Точки'},
    ];

    public static STATUSES_DESCRIPTION = [
        {'id': 0, 'name': 'Запрос'},
        {'id': 1, 'name': 'Одобрен'},
        {'id': 2, 'name': 'Отменен'},
    ];


}

export class Transit extends BaseModel {
    public static TYPES_DESCRIPTION = [
        {'id': 1, 'name': 'Перемещение'},
        {'id': 2, 'name': 'Возврат'},
    ];
    public static STATUSES_DESCRIPTION = [
        {'id': 1, 'name': 'Новое'},
        {'id': 2, 'name': 'В доставке'},
        {'id': 3, 'name': 'Доставлено'},
        {'id': 4, 'name': 'Отмена'},
    ];


    public static STATUS_NEW = 1;
    public static STATUS_ONDELIVERY = 2;
    public static STATUS_DELIVERED = 3;
    public static STATUS_CANCELED = 4;

    public static TYPE_TRANSIT = 1;
    public static TYPE_RETURN = 2;


    public packs: Packs[];
    public files: TransitFile[];
    public status: number;
    public start_date: string;
    public courier?: User;
    public hub_origin?: Hub;
    public hub_origin_id?: number;
    public hub_destination?: Hub;
    public hub_destination_id?: number;
    public transit_driver_id?: number;
    public transit_driver?: TransitDriver;
    public transit_vehicle_id?: number;
    public transit_vehicle?: TransitVehicle;
    public transit_carrier_id?: number;
    public transit_carrier?: TransitCarrier;
}

/** Файлы перемещений */
export class TransitFile extends BaseModel {
    public static fileTypes = [
        {type: 'waybill', name: 'Накладная'},
        {type: 'torg_13', name: 'Торг-13'},
    ];

    public transit_id: number;
    public file_type: string;
    public file_path: string;
    public file_url: string;
    public user_id: number;
}

/** Водитель, перевозящий перемещение */
export class TransitDriver extends BaseModel {
    public name: string;
    public phone?: string;
    public organization_name?: string;
    public organization_address?: string;
    public organization_phone?: string;

    public passport_number?: string;
    public passport_issuer?: string;
    public passport_date?: string;
    public passport_issuer_code?: string;
}

/** Машина, перевозящая перемещение */
export class TransitVehicle extends BaseModel {
    public static ownershipTypes = [
        {id: 1, name: 'собственность'},
        {id: 2, name: 'аренда'},
        {id: 3, name: 'лизинг'},
    ];

    public reg_number: string;
    public model: string;
    public weight_capacity?: number;
    public volume_capacity?: number;
    public ownership_type?: number;
}

/** Организация, перевозящая перемещение */
export class TransitCarrier extends BaseModel {
    public name: string;
    public inn: string;
    public kpp: string;
    public fact_address: string;
    public jur_address: string;
    public phones: string;
}

export class ChangesLogger extends BaseModel {
    public item_id: number;
    public user_id: number;
    public user: User;
    public action: string;

    public new_values;
    public old_values;
}

export class MatrixTopDeliveryZone extends BaseModel {
    public number: number;
    public is_central: number;
}

/**
 * Рабочий день курьера
 */
export class CourierWorkday extends BaseModel {
    public courier_id: number;
    public date: string;
    public time_start: string;
    public time_end: string;
    public hours: number;
    public mileage: number;
    public kgt_payments: number;
    public cargo_tariff_id: number;
    public hours_cost: number;
    public mileage_cost: number;
    public salary_calculated: number;
    public salary: number;
    public approved: boolean;
    public approve_datetime: string;
    public approve_manager_id: number;
    public canceled: boolean;
    public cancel_datetime: string;
    public cancel_manager_id: number;
    public logistic_notes: string;

    public courier_options?: CourierOption;
    public cargo_tariff?: CargoTariff;
    public courier_default_hub_id?: number;
    public is_foreign_hub_tariff?: boolean;
    public salary_payed: boolean;
    public closed_by_warehouse: boolean;

    public time_start_hours?: string;
    public time_start_minutes?: string;
    public time_end_hours?: string;
    public time_end_minutes?: string;

    public hours_to_pay: number;

    public extra_expenses_pending_sum?: number;
    public extra_expenses_approved_sum?: number;

    constructor(data: any) {
        super(data);

        this.time_start = (data.time_start) ? data.time_start.substring(0, 5) : null;
        this.time_end = (data.time_end) ? data.time_end.substring(0, 5) : null;

        this.approved = !!this.approved;
        this.canceled = !!this.canceled;

        this.hours_to_pay = this.hours;

        this.courier_options = new CourierOption(data.courier_options);
        if (data.cargo_tariff) {
            this.cargo_tariff = new CargoTariff(data.cargo_tariff);
            this.is_foreign_hub_tariff = this.cargo_tariff.hub_id !== this.courier_default_hub_id;

            /**
             * RESEARCH-1062: по часам оплачиваем
             * если нет минималки - целиком
             * если есть минималка - минималка плюс сверх 14
             */
            if (1 * this.cargo_tariff.minimal_salary) {
                this.hours_to_pay = Math.max(this.hours - 14, 0);
            }
        }

        if (data.extra_expenses_pending_sum) {
            this.extra_expenses_pending_sum = 1 * data.extra_expenses_pending_sum;
        }

        if (data.extra_expenses_approved_sum) {
            this.extra_expenses_approved_sum = 1 * data.extra_expenses_approved_sum;
        }

        this.salary_payed = !!this.salary_payed;
        this.closed_by_warehouse = !!this.closed_by_warehouse;

        this.time_start_hours = (data.time_start) ? data.time_start.substring(0, 2) : null;
        this.time_start_minutes = (data.time_start) ? data.time_start.substring(3, 5) : null;
        this.time_end_hours = (data.time_end) ? data.time_end.substring(0, 2) : null;
        this.time_end_minutes = (data.time_end) ? data.time_end.substring(3, 5) : null;
    }
}

/**
 * Тариф курьера-Пятитонника
 */
export class CargoTariff extends BaseModel {
    public id: number;
    public hub_id: number;
    public name: string;
    public hours_cost: number;
    public mileage_cost: number;
    public minimal_salary: number;

    public name_full: string;

    public is_foreign_hub_tariff?: boolean;

    constructor(data: any) {
        super(data);

        this.name_full = data.name + ' [' + data.id + ']';
    }
}

/**
 * Пользователь-курьер
 */
export class CourierUser extends User {
    public old_courier_id: number;
}

export class Template {
    /** @var string  Тип уведомления отправляемого в ИМ, получатель нет на месте */
    public static TYPE_IM_RECIPIENT_NOT_IN_PLACE = 30;
    /** @var string Тип уведомления отправляемого в ИМ, получатель не отвечает */
    public static TYPE_IM_RECIPIENT_NOT_RESPOND = 31;
}

export class ClientSetting extends BaseModel {
    public client_id: number;
    public setting_key: ClientSettingKey;
    public setting_value: string | boolean | number;
}

export class ClientSettingKey {
    /** @var string Всегда отправлять СМС */
    public static readonly MASTER_SMS = 'master_sms';

    /** @var string Всегда звонить */
    public static readonly MASTER_CALL = 'master_call';

    /** @var string Всегда вкладывать накладную */
    public static readonly MASTER_DOCS = 'master_docs';

    /** @var string Всегда возвращать накладную */
    public static readonly MASTER_DOCS_RETURN = 'master_docs_return';


    /** @var string Печатать внутр. номер на этикетке */
    public static readonly PRINT_INNER_NUMBER_ON_LABEL = 'print_inner_number_on_label';

    /** @var string Не использовать объёмный вес при расчёте */
    public static readonly CALC_USE_WEIGHT = 'calc_use_weight';

    /** @var string Использовать объёмный вес при расчёте в Москве и МО */
    public static readonly CALC_USE_VOLUME_WEIGHT_MO = 'calc_use_volume_weight_mo';

    /** @var string Имеет доступ к API чеков */
    public static readonly HAS_RECEIPTS_API = 'has_receipts_api';

    /** @var string Клиент по оферте */
    public static readonly IS_OFFER = 'is_offer';

    /** @var string Кол-во заказов в месяц */
    public static readonly ORDERS_COUNT_MONTHLY = 'orders_count_monthly';

    /** @var string Текст для подписи в клиентском приложении */
    public static readonly TEXT_FOR_SIGNATURE = 'text_for_signature';

    /** @var string Проверка заказа/вещей отключена */
    public static readonly FITTING_DISABLED = 'fitting_disabled';

    /** @var string Исключать из финансовых отчётов */
    public static readonly EXCLUDE_FROM_FINANCIAL_REPORTS = 'exclude_from_financial_reports';

    /** @var string Занос КГТ разрешён */
    public static readonly MASTER_SKID_KGT = 'master_skid_kgt';

    /** @var string Частичный выкуп разрешён */
    public static readonly MASTER_BUYBACK = 'master_buyback';

    /** @var string максимальная объявленная ценность */
    public static readonly MAX_OS = 'max_os';

    /** @var string Показывать в статистике к собранию */
    public static readonly SHOW_IN_MEETING_STAT = 'show_in_meeting_stat';

    /** @var string Тарификация только выкупленных заказов */
    public static readonly CALC_ONLY_PURCHASED_ORDERS = 'calculate_only_purchased_orders';

    /** @var string Тарификация по почтовому индексу */
    public static readonly CALC_BY_POSTAL_CODE = 'calculate_by_postal_code';

    /** @var string Тарификация по почтовому индексу, полученному от клиента */
    public static readonly CALC_BY_CLIENT_POSTAL_CODE = 'calculate_by_client_postal_code';

    /** @var string Тестовый клиент */
    public static readonly IS_TEST = 'is_test';

    /** @var string Клиент Логсис */
    public static readonly IS_LOGSIS = 'is_logsis';

    /** @var string Email: отправка отчётов по статусам заказов */
    public static readonly EMAIL_ORDER_STATUSES_REPORTS = 'email_order_statuses_reports';

    /** @var string Email: отправка отчётов по возвратам */
    public static readonly EMAIL_ORDER_RETURNS_REPORTS = 'email_order_returns_reports';

    /** @var string Email: отправка отчётов по платёжным документам */
    public static readonly EMAIL_PAYMENTS_REPORTS = 'email_payments_reports';

    /** @var string отправлять ли клиенту отчеты по платежам */
    public static readonly SEND_PAYMENTS_REPORTS = 'send_payments_reports';

    /** @var string отправлять ли клиенту возвратные акты */
    public static readonly SEND_RETURN_ACTS = 'send_return_acts';

    /** @var string минимальный интервал забора */
    public static readonly MINIMAL_ZORDER_INTERVAL = 'minimal_zorder_interval';

    /** @var string Максимальный неконтролируемый вес */
    public static readonly MAX_UNCONTROLLED_WEIGHT = 'max_uncontrolled_weight';

    /** @var string Печатать чеки для предоплаченных заказов */
    public static readonly NEED_RECEIPT_FOR_PREPAID_ORDERS = 'need_receipt_for_prepaid_orders';

    /** @var float Коэф. за доставку день в день */
    public static readonly SAMEDAY_COEF = 'sameday_coef';

    /** @var string Акт ПП: Первая строка с данными */
    public static readonly ACT_DATA_START_LINE = 'act_data_start_line';

    /** @var string Акт ПП: Столбец с данными */
    public static readonly ACT_DATA_COLUMN_NUMBER = 'act_data_column_number';

    /** @var string Акт ПП: Загрузка по местам */
    public static readonly ACT_IS_BY_PLACES_BARCODES = 'act_is_by_places_barcodes';

    /** @var string Показ подписей получателей */
    public static readonly DISPLAY_RECIPIENTS_SIGNATURES = 'display_recipients_signatures';

    /** @var string Акт расхождений: Разрешено создание */
    public static readonly ACT_DIFF_ALLOWED = 'act_diff_allowed';

    /** @var string Акт расхождений: Полный адрес организации */
    public static readonly ACT_DIFF_FULL_ADDRESS = 'act_diff_full_address';

    /** @var string Акт расхождений: Структурное подразделение */
    public static readonly ACT_DIFF_DIVISION = 'act_diff_division';

    /** @var string Акт расхождений: Место приёмки товара */
    public static readonly ACT_DIFF_RECEPTION_LOCATION = 'act_diff_reception_location';


    public static readonly SPB_TO_MSK = 'spb_to_msk';

    /** @var string Возвращать все отказные товары заказа в одном акте */
    public static readonly RETURN_ALL_GOODS_WITH_SINGLE_ACT = 'return_all_goods_with_single_act';

    /** @var string Возвращать частичный отказ в отдельном акте */
    public static readonly RETURN_PARTIAL_REFUSE_WITH_SEPARATE_ACT = 'return_partial_refuse_with_separate_act';

    /** @var string Возврат по складам */
    public static readonly RETURN_PER_WAREHOUSE = 'return_per_warehouse';

    /** @var string Возврат по складам */
    public static readonly IS_NEED_PRETENSION_ON_FULL_REFUZE = 'is_need_pretension_on_full_refuze';

    /** @var string Бесплатная доставка при полном отказе */
    public static readonly IS_PRICELESS_DELIVERY_ON_REFUZE = 'is_priceless_delivery_on_refuze';

    /** @var string Клиент забирает возвраты самостоятельно */
    public static readonly IS_CLIENT_TAKE_RETURNS_AWAY_BY_HIMSELF = 'is_client_take_returns_away_by_himself';

    /** @var string Не отправлять сообщения каскадом */
    public static readonly DONT_SEND_MESSAGE_IN_CASCADE = 'dont_send_message_in_cascade';

    /** @var string Разрешено создавать обмен с возвратом */
    public static readonly ALLOW_ORDERS_WITH_EXCHANGE = 'allow_orders_with_exchange';

    /** @var string Разрешен клиентский возврат */
    public static readonly ALLOW_CLIENT_RETURN = 'allow_client_return';

    /** @var string Рекомендованные для передачи в КГТ */
    public static readonly RECOMMENDED_FOR_TRANSFER_KGT = 'recommended_for_transfer_kgt';

    /** @var string Не отправлять смс при выдаче на доставку */
    public static readonly DONT_SEND_SMS_ORDER_ON_DELIVERY = 'dont_send_sms_order_on_delivery';

    /** @var string Разрешено добавлять новые заказы в акт приёма передачи */
    public static readonly IS_ALLOWED_ADD_NEW_ORDER_TO_TRANSCEIVER_ACT = 'is_allowed_add_new_order_to_transceiver_act';

    /** @var string Без ограничений по расстоянию для передачи в хаб КГТ */
    public static readonly NO_DISTANCE_LIMIT_FOR_HUB_KGT = 'no_distance_limit_for_hub_kgt';

    /** @var string Клиент передает маркированную тару */
    public static readonly CLIENT_BRING_MARKED_TARE = 'client_bring_marked_tare';

    /** @var string Не оценивать заказы клиента */
    public static readonly DONT_RATE_ORDER = 'dont_rate_order';

    /** @var string Отправлять акты излишков / недостач по email */
    public static readonly IS_NEED_SEND_EXCESS_OR_DEFICIT_ACTS_BY_EMAIL = 'is_need_send_excess_or_deficit_acts_by_email';

    /** @var string ' актов излишков / недостач  */
    public static readonly EMAIL_FOR_RECEIVING_EXCESS_OR_DEFICIT_ACTS = 'email_for_receiving_excess_or_deficit_acts';

    /** @var string Дата включения чеков Orange для заказов, оплаченных наличными */
    public static readonly NEED_ORANGE_RECEIPT_FOR_CASH_PAYMENTS_SINCE = 'need_orange_receipt_for_cash_payments_since';

    /** @var string Клиент не передаёт кол-во мест в заказе */
    public static readonly CLIENT_NOT_SEND_COUNT_PLACES = 'client_not_send_count_places';

    /** @var string Отправляем отчёты по статусам */
    public static readonly IS_SEND_STATUSES_REPORTS = 'is_send_statuses_reports';

    /** @var string Клиент входит в TOP 10 */
    public static readonly IS_TOP_10_CLIENTS = 'is_top_10_clients';

    /** @var string Возврат юрлица отвозится на склад юрлица, а не на склад связанного аккаунта */
    public static readonly SEPARATE_RETURNS_FOR_AFFILIATED_CLIENTS = 'separate_returns_for_affiliated_clients';

    /** @var string Сезонные тарифы включены */
    public static readonly OVERPRICE_TARIFFS_ENABLED = 'overprice_tariffs_enabled';

    /** @var string Сезонные тарифы: коэффициент */
    public static readonly OVERPRICE_TARIFFS_RATE = 'overprice_tariffs_rate';

    /** @var string Разрещен многоместный клиентский возврат **/
    public static readonly IS_MULTIPLACES_RETURNS_ENABLED = 'is_multiplaces_returns_enabled';

    /** @var string Разрешены услуги монтажа */
    public static readonly ALLOW_INSTALLATION_SERVICE = 'allow_installation_service';

    /** @var string ПВЗ: Разрешено использование */
    public static readonly PICKUP_POINT_SHIPMENT_AVAILABLE = 'pickup_point_shipment_available';

    /** @var string ПВЗ: Тариф за выдачу заказа */
    public static readonly PICKUP_POINT_SHIPMENT_PRICE = 'pickup_point_shipment_price';

    /** @var string ПВЗ: Кол-во дней бесплатного хранения */
    public static readonly PICKUP_POINT_STORAGE_FREE_DAYS = 'pickup_point_storage_free_days';

    /** @var string ПВЗ: Коэффициент тарификации за день превышения срока хранения */
    public static readonly PICKUP_POINT_STORAGE_OVERDUE_TARIFF_RATE = 'pickup_point_storage_overdue_tariff_rate';

    /** @var string Учитывать видимость заказов в запросах API */
    public static readonly HONOR_ORDER_VISIBILITY_IN_API = 'honor_order_visibility_in_api';

    /** @var string По умолчанию скрывать из API заказы, создаваемые через ЛК (и импортом Excel) */
    public static readonly HIDE_LK_ORDERS_FROM_API_BY_DEFAULT = 'hide_lk_orders_from_api_by_default';

    public key: string;
    public name: string;
    public is_editable: number;
    public type: string;
    public created_at: Date;
    public updated_at: Date;
}

export class ParentCompany extends BaseModel {
    public is_active: number;
    public name: string;
    public full_name: string;
    public inn: string;
    public ogrn: string;
    public okpo: string;
    public ur_address: string;
    public fact_address: string;
    public bank_name: string;
    public bank_bik: string;
    public bank_corr: string;
    public bank_account: string;
    public dir_fio: string;
    public dir_fio_rod: string;
    public dir_fio_short: string;
    public buh_fio: string;
    public buh_fio_rod: string;
    public buh_fio_short: string;
    public tax_type: string;
    public tax_type_print: string;
    public selected?: boolean;
}

export class DocReturnAct extends BaseModel {
    // Формируется
    public static STATUS_OPENED = 1;

    // Сформирован
    public static STATUS_CLOSED = 2;

    // Подписан
    public static STATUS_SIGNED = 3;

    public static STATUS_DESCRIPTION = [
        '',
        'Формируется',
        'Сформирован',
        'Подписан',
    ];

    public old_akt_id?: number;
    public client_id: number;
    public status: number;
    public act_date: string;
    public signed_act_return_date?: string;
    public client?: DocReturnActClientInfo;
    public act_orders_with_info?: DocReturnActOrder[];
}

export class DocReturnActOrder extends BaseModel {
    // Актирован
    public static STATUS_ACTIVE = 1;

    // Удалён из акта
    public static STATUS_DELETED = 2;

    public static STATUS_DESCRIPTION = [
        '',
        'Актирован',
        'Удалён из акта',
    ];

    public doc_return_act_id?: number;
    public old_akt_order_id?: number;
    public client_id: number;
    public order_id?: number;
    public order_uid?: string;
    public barcode?: string;
    public inner_n?: string;
    public date_add: string;
    public status?: number;

    public order_status?: number;
    public order_target_name?: string;
    public order_recipient_name?: string;
}

export class DocReturnActClientInfo extends BaseModel {
    public id: number;
    public client_uid: string;
    public client_uid_old: string;
    public name: string;
    public full_name: string;
    public ceo?: string;
    public ceo_rod?: string;
    public parent_id?: number;
    public company?: DocReturnActClientParentCompanyInfo;
}

export class DocReturnActClientParentCompanyInfo extends BaseModel {
    public id: number;
    public full_name: string;
    public dir_fio_rod: string;
    public dir_fio_short: string;
}

export class DocReturnActOrderResponseUnit extends BaseModel {
    public order_id: number;
    public order_uid: string;
    public order_inner_n: string;
    public order_barcode: string;
    public order_delivery_date: string;
    public order_status: number;
    public order_target_name: string;
    public order_recipient_name: string;
    public order_option_returned_doc: number;
    public order_option_is_document_returned?: number;
    public order_option_document_receive_status?: number;

    public client_id: number;
    public client_uid: string;
    public client_uid_old: string;
    public client_name: string;
    public client_full_name: string;

    public courier_id?: number;
    public courier_name?: string;
    public courier_full_name?: string;

    public doc_return_act_id?: number;
    public doc_return_act_date?: string;
    public doc_return_act_status?: number;
    public doc_return_act_order_date_add?: string;
    public doc_return_act_order_status?: number;

    public _x_status?: string;
    public _x_force_fix_state_available?: boolean;
}

export class JobProgress extends BaseModel {
    public static STATUS_CREATED = 'created';
    public static STATUS_STARTED = 'started';
    public static STATUS_PROCESSED = 'processed';

    public status: string;
    public job_name: string;
    public description: string;
    public status_description: string;
    public errors_array: [];
    public results_array: [];

    public progress: number;
    public total_items: number;

    public started_at: Date;
    public processed_at: Date;

    public user: User;
}

export class BadAddressedTarget extends BaseModel {
    uid?: string | null;
    inner_n?: string | null;
    date?: string | null;
    status?: string | null;
    target_type: string | null;
    target_lat: string | number | null;
    target_lon: string | number | null;
    target_address_text: string;

    address_id: number;
    address_lat: string | number | null;
    address_lon: string | number | null;
    address_geo_lat: string | number | null;
    address_geo_lon: string | number | null;
    address_qc: number;
    address_qc_geo: number;
    address_quality: number;

    address_requires_call_id?: number;
    address_blacklist_coords_id?: number;

    x_address_requires_call = false;
    x_address_blacklist_coords = false;
    x_address_quality_bad_forever = false;
    x_address_quality_bad = false;
    x_address_quality_neutral = false;
    x_address_quality_good = false;
    x_address_coords_empty = false;
    x_target_coords_empty = false;
    x_coords_equals = false;
    x_coords_mismatch = false;
    x_bad_dadata = false;

    constructor(data: any) {
        super(data);

        this.x_address_requires_call = !!this.address_requires_call_id;
        this.x_address_blacklist_coords = !!this.address_blacklist_coords_id;

        this.x_address_quality_bad_forever = (-128 === this.address_quality);
        this.x_address_quality_bad = (0 > this.address_quality);
        this.x_address_quality_neutral = (0 === this.address_quality);
        this.x_address_quality_good = (0 < this.address_quality);

        this.x_bad_dadata = (this.address_qc_geo >= 4 && this.address_qc > 2);

        this.x_address_coords_empty = (null === this.address_lat || null === this.address_lon);
        this.x_target_coords_empty = (null === this.target_lat || null === this.target_lon);

        this.x_coords_equals = CoordsHelper.IsCoordsEquals([
            this.target_lat, this.target_lon,
        ], [
            this.address_lat, this.address_lon,
        ]);

        this.x_coords_mismatch = (!this.x_address_coords_empty && !this.x_target_coords_empty && !this.x_coords_equals);
    }
}

export class Warehouse extends BaseModel {
    old_sklad_id?: number;
    name?: string;
    client_id: number;
    comments?: string;
    contact_name?: string;
    contact_phone?: string;
    mapper_id?: number;
    mapper_result: string;
    address_text: string;
    working_hours?: string;
    inner_n?: string;
    returns_possible: boolean;
    lat?: number;
    lon?: number;
    hub_id: number;
    client_code?: string;
    visible: boolean;

    constructor(data: any) {
        super(data);

        this.returns_possible = !!data.returns_possible;
        this.visible = !!data.visible;

        if (data.lat && data.lon) {
            this.lat = ('string' === typeof data.lat) ? Number.parseFloat(data.lat) : data.lat;
            this.lon = ('string' === typeof data.lon) ? Number.parseFloat(data.lon) : data.lon;
        }
    }
}

export class Zorder extends BaseModel {
    public static readonly STATUS_NEW = 1;
    public static readonly STATUS_APPROVED = 2;
    public static readonly STATUS_DONE = 3;
    public static readonly STATUS_CANCEL = 4;
    public static readonly STATUS_DATE_CHANGED = 6;
    public static readonly STATUS_ON_STOCK = 7;
    public static readonly STATUS_REFUSE = 8;

    public static readonly STATUS_DESCRIPTION = [
        '',
        'Новый',
        'Подтвержден',
        'Выполнен',
        'Отменен',
        '',
        'Перенос',
        'На складе',
    ];

    public static STATUSES_FOR_EDIT = [
        {'id': Zorder.STATUS_DONE, name: 'Выполнен'},
        {'id': Zorder.STATUS_CANCEL, name: 'Отмена'},
        {'id': Zorder.STATUS_REFUSE, name: 'Отказ на месте'},
    ];

    public static STATUSES_FOR_EDIT_FOR_ADMIN = [
        {'id': Zorder.STATUS_NEW, name: 'Новый'},
        {'id': Zorder.STATUS_APPROVED, name: 'Подтвержден'},
        {'id': Zorder.STATUS_DONE, name: 'Выполнен'},
        {'id': Zorder.STATUS_CANCEL, name: 'Отмена'},
        {'id': Zorder.STATUS_REFUSE, name: 'Отказ на месте'},
    ];

    old_zorder_id?: number;
    client_id: string | number;
    courier_id?: number;
    inner_n?: string;
    date?: string;
    is_sameday: boolean | number;
    time_begin?: string;
    time_end?: string;
    warehouse_id?: number;
    zone_id?: number;
    comments?: string;
    status: number;
    price?: number;
    bill_id_1c?: string;
    weight?: number;
    capacity?: number;
    car_type: number;
    places_count?: number;
    link_bill_id_1c?: string;
    sync_date: string;
    mo_mkad?: number;
    real_price_courier?: number;
    calc_price_courier?: number;
    substatus?: number;
    test_calc_price_courier: number;
    test_real_price_courier: number;
    warehouse_address_id?: number;
    address_text?: string;
    warehouse_hub_id?: number;
    contact_name?: string;
    contact_phone?: string;
    lat?: number;
    lon?: number;

    warehouse?: Warehouse;
    client?: any;
    courier?: any;

    constructor(data: any) {
        super(data);

        if (data.lat && data.lon) {
            this.lat = ('string' === typeof data.lat) ? Number.parseFloat(data.lat) : data.lat;
            this.lon = ('string' === typeof data.lon) ? Number.parseFloat(data.lon) : data.lon;
        }
    }

    /**
     * Возвращает признак возможности пересчёта стоимости забора по его статусу
     * @param zorder
     */
    public static isZorderRecalcable(zorder: Zorder): boolean {
        return (zorder.status === Zorder.STATUS_DONE || zorder.status === Zorder.STATUS_ON_STOCK);
    }

    /**
     * Возвращает признак возможности пересчёта стоимости текущего забора по его статусу
     */
    public isRecalcable(): boolean {
        return Zorder.isZorderRecalcable(this);
    }
}

export interface IShippingQueueRecordStatus extends IBaseRecordStatus {
};

export class ShippingQueue extends BaseModel {
    /** @var int Не пришёл */
    public static readonly COURIER_DOES_NOT_COME = 0;

    /** @var int Пришёл (Ожидание сборки) */
    public static readonly COURIER_WAIT = 1;

    /** @var int В режиме сборки (Сборка) */
    public static readonly COURIER_ASSEMBLE = 2;

    /** @var int Ожидание погрузки */
    public static readonly COURIER_ASSEMBLED = 3;

    /** @var int Погрузка */
    public static readonly COURIER_LOADING = 4;

    /** @var int Собран (Собран) */
    public static readonly COURIER_READY = 5;

    /**
     * Возможные статусы курьера в очереди на сборку и погрузку
     */
    public static readonly COURIER_STATUSES: IShippingQueueRecordStatus[] = [
        {id: ShippingQueue.COURIER_DOES_NOT_COME, name: 'Не пришёл', classes: 'badge badge-danger', _special: true},
        {id: ShippingQueue.COURIER_WAIT, name: 'Пришёл', classes: 'badge badge-primary'},
        {id: ShippingQueue.COURIER_ASSEMBLE, name: 'В сборке', classes: 'badge badge-warning'},
        {id: ShippingQueue.COURIER_ASSEMBLED, name: 'Собран', classes: 'badge badge-warning'},
        {id: ShippingQueue.COURIER_LOADING, name: 'Погрузка', classes: 'badge badge-info'},
        {id: ShippingQueue.COURIER_READY, name: 'Отгружен', classes: 'badge badge-success'},
    ];

    date: string;
    worker_id?: number;
    courier_id: number;
    time_come: string;
    time_start?: string;
    time_end?: string;
    loading_start?: string;
    loading_end?: string;
    status: number;
    old_id?: number;
    priority: number;
    is_unique?: number;
    shift_number: number;
    cart_number?: number;
    assembling_gate_id?: number;
    loading_gate_id?: number;

    assemblier?: User;
    courier?: User;
    assembling_gate?: HubGate;
    loading_gate?: HubGate;

}

export class AssemblingSession {
    id: number;
    hub_id: number;
    shipping_queue_id: number;
    worker_id: number;
    time_start: string;
    time_end?: string;
    status: number;

    hub?: Hub;
    shippingQueue?: ShippingQueue;
    worker?: User;
}

export class AssemblingTaskOrderPlace {
    /** @var int Ожидает сканирования */
    public static readonly STATUS_PENDING = 1;

    /** @var int Просканирован на добавление */
    public static readonly STATUS_SCANNED = 2;

    /** @var int Просканирован на исключение по причине курьера (например, не влезло) */
    public static readonly STATUS_EXCLUDED_BY_COURIER = 2;

    /** @var int Просканирован на исключение по причине логистики (например, на другого кинули) */
    public static readonly STATUS_EXCLUDED_BY_LOGISTICS = 3;

    id: number;
    shipping_queue_id: number;
    order_id: number;
    place_id: number;
    scanned_at?: string;
    is_extra_addon?: number;
    should_be_excluded?: number;
    status: number;

    order?: Order;
    place?: OrderBarCode;

    _order_overall_status?: number;
    _order_sort_metric?: number;

    _recently_scanned?: boolean;
    _order_recently_scanned?: boolean;

    _already_scanned?: boolean;
    _order_already_scanned?: boolean;
}

export class Ibox extends BaseModel {
    public static readonly PROVIDERS = [
        {id: 1, name: 'ibox'},
        {id: 2, name: 'assist'},
        {id: 3, name: 'sbp'}
    ];
    public static readonly PROVIDER_IBOX = 1;
    public static readonly PROVIDER_ASSIST = 2;
    public static readonly PROVIDER_SBP = 3;

    public id: number;
    public provider: number;
    public json?: string;
    public ibox_login?: string;
    public amount: number;
    public order_id?: number;
    public uid?: string;
    public TID: string;
    public status: string;
    public payment_id: string;
    public is_return: boolean;
    public date: Date;
    public old_ibox_id?: number;
    public ref_ibox_id?: number;
}


export class Terminals extends BaseModel {

    /** @var int В наличии (не привязан курьер) */
    public static readonly STATUS_AVAILABLE = 1;

    /** @var int В работе */
    public static readonly STATUS_IN_WORK = 2;

    /** @var int В логистике */
    public static readonly STATUS_IN_LOGISTIC = 3;

    /** @var int В iT */
    public static readonly STATUS_IN_IT = 4;

    /** @var int В ремонте */
    public static readonly STATUS_IN_REPARING = 5;

    /** @var int Возвращён */
    public static readonly STATUS_RETURNED = 6;

    public id: number;
    public terminal_serial: number;
    public terminal_type: number;
    public status: number;
    public hub_id: number;
    public courier_id: number;
    public comment: number;
    public returned_at: Date;
    public created_at: Date;
    public updated_at: Date;

    public status_name?: string;
    public terminal_type_name?: string;
    public courier_option?: CourierOption;
    public hub?: Hub;
    public courier?: User;
    public is_master_terminal?: boolean;

    constructor(data: any) {
        super(data);
        if (data) {
            if (data.hasOwnProperty('is_master_terminal')) {
                this.is_master_terminal = !!data.is_master_terminal;
            }
        }
    }
}


export class PpAct extends BaseModel {
    /** @var string Основной акт */
    public static readonly TYPE_MAIN = 1;
    /** @var string Излишки */
    public static readonly TYPE_EXCESS = 2;
    /** @var int  Недостачи */
    public static readonly TYPE_DEFICIT = 3;

    /** @var string Новый */
    public static readonly STATUS_NEW = 1;
    /** @var string Передан с забором */
    public static readonly STATUS_SENDED = 2;
    /** @var string В обработке */
    public static readonly STATUS_UNDER_PROCESSING = 3;
    /** @var string Закрыт */
    public static readonly STATUS_CLOSED = 4;

    public id: number;
    public number: string;
    public client_id: number;
    public parent_act_id: number;
    public zorder_id: number;
    public transceiver_act_id: number;
    public has_deficits: number;
    public ordinal_number: number;
    public type: number;
    public status: number;
    public status_name?: string;
    public source: number;
    public source_name?: string;
    public has_excesses: number;
    public date: number;
    public closed_at: Date;
    public created_at: Date;
    public updated_at: Date;
    public closed_user_name?: string;

    public places?: PpActPlace;
    public accepted_places?: PpActPlace;
    public accepted_places_count?: number;
    public places_count?: number;
    public is_deficit?: boolean;
    public client?: ClientInfo;
    public hub?: Hub;
}

export class PpActPlace extends BaseModel {
    /** @var string Новый */
    public static readonly STATUS_NEW = 1;
    /** @var string Принят */
    public static readonly STATUS_RECEIVED = 2;
    /** @var string Отсутствует */
    public static readonly STATUS_ABSENT = 3;

    public id: number;
    public act_id: number;
    public order_id: number;
    public order_place_id: number;
    public status: number;
    public received_by: number;
    public created_at: Date;
    public updated_at: Date;
}

export class PpActClient extends BaseModel {
    /** @var int Статус Новый */
    public static readonly STATUS_NEW = 1;

    /** @var int Статус Загружен до приёма заказов */
    public static readonly STATUS_LOADED = 2;

    /** @var int Статус Постобработан */
    public static readonly STATUS_POSTPROCESSED = 3;

    /** @var int ШК: внутренний номер */
    public static readonly BARCODE_TYPE_INNER_N = 1;

    /** @var int ШК: место заказа */
    public static readonly BARCODE_TYPE_ORDER_PLACE = 2;

    public date: string;
    public status: number;
    public status_name: string;
    public path: string;
    public url: string;
    public barcode_type: number;
    public barcodes: string[];
    public clients?: ClientInfo[];
    public files?: PpActClientFile[];
    public hub_id: number;
    public hub: Hub;
}

export class PpActClientFile extends BaseModel {
    public path: string;
    public url: string;
    public barcode_type: number;
    public barcodes: string[];
}

export class ReturnAct extends BaseModel {
    /** @var int Новый */
    public static readonly STATUS_NEW = 1;
    /** @var int Сформирован */
    public static readonly STATUS_FORMED = 2;
    /** @var int На доставке */
    public static readonly STATUS_ONDELIVERY = 3;
    /** @var int Доставлен */
    public static readonly STATUS_DELIVERED = 4;
    /** @var int Подписан и возвращен */
    public static readonly STATUS_SIGNED_RETURNED = 5;
    /** @var int В архиве */
    public static readonly STATUS_ARCHIVED = 6;

    public static readonly STATUSES_DESCRIPTION = [
        {'id': 1, 'name': 'Новый'},
        {'id': 2, 'name': 'Сформирован'},
        {'id': 3, 'name': 'На доставке'},
        {'id': 4, 'name': 'Доставлен'},
        {'id': 5, 'name': 'Подписан и возвращен'},
        {'id': 6, 'name': 'В архиве'},
    ];

    public static readonly REASON_THE_CAR_IS_NO_PLACE = 1;
    public static readonly REASON_THE_COURIER_LEFT = 2;
    public static readonly REASON_OTHER = 3;
    public static readonly REASONS_DESCRIPTION = [
        {'id': 1, 'name': 'Не влезло'},
        {'id': 2, 'name': 'Уехал'},
        {'id': 3, 'name': 'Другое'},
    ];

    /** @var int Тип - любой возврат */
    public static readonly TYPE_ANY = 1;
    /** @var int Тип - невскрытый */
    public static readonly TYPE_UNOPENED = 2;
    /** @var int Тип - вскрытый */
    public static readonly TYPE_OPENED = 3;

    public static readonly TYPE_DESCRIPTIONS = [
        {'id': 1, 'name': 'Все заказы'},
        {'id': 2, 'name': 'Не вскрытые заказы'},
        {'id': 3, 'name': 'Вскрытые заказы'},
    ];


    public old_vact_id?: string;
    public client_id: number;
    public hub_id: number;
    public date: string | Date;
    public zorder_id?: number;
    public courier_id?: number;
    public status: number;
    public type?: number;
    public sync_date?: string | Date;
    public warehouse_to_id?: number;
    public is_opened?: number;
    public closed_by?: number;
    public closed_at?: string | Date;
    public signed_received_by?: number;
    public signed_received_at?: string | Date;

    public type_name?: string;
    public status_name?: string;
    public orders_count?: number;
    public goods_count?: number;


    public courier?: User;
    public client?: ClientInfo;
    public goods?: OrderGood[];
    public orders?: Order[];
    public zorder?: Zorder;
    public warehouse_to?: Warehouse;
    public files?: File[];
}

/**
 * Файлы заказов
 * @deprecated use File
 */
export class OrderFile extends BaseModel {
    public static readonly TYPE_PRETENSION = 1;
    public static readonly TYPE_PARKING = 2;
    public static readonly TYPE_LOST = 3;

    public static readonly TYPE_DESCRIPTION = [
        'Претензионный лист',
        'Парковки',
        'Утрата заказа',
    ];

    public order_id: number;
    public courier_id?: number;
    public courier?: User;

    public description?: string;

    public file_type: number;
    public file_url: string;
    public full_url: string;
}


export class File extends BaseModel {
    public static readonly TYPE_ORDER_PRETENSION = 1;
    public static readonly TYPE_ORDER_PARKING = 2;
    public static readonly TYPE_ORDER_LOST = 3;
    public static readonly TYPE_RETURN_ACT_IMAGE = 4;
    public static readonly TYPE_ANY_ETC = 5;

    public static readonly TYPE_DESCRIPTION = [
        'Претензионный лист',
        'Парковки',
        'Утрата заказа',
        'Возвратный акт',
        'Прочее',
        'Договор',
        'Фотоинструкция по возврату накладных',
        'Пример реестра (Excel) для загрузки в ЛК данного клиента',
    ];

    public item_type: string;
    public item_id: number;
    public item?: any;

    public type?: number;
    public description?: string;

    public path: string;
    public url?: string;

    public uploader_id?: number;
    public uploader?: User;

    constructor(data: any) {
        super(data);
    }
}

export interface IFileType {
    id: number;
    explain: string;
}

export class ClientServiceCategory extends BaseModel {
    client_id?: number;
    service_category_id: number;
    service_category_name?: string;
    is_available?: number | boolean;
    is_displayed_in_agent_report?: number | boolean;
}

export class ServiceCategory extends BaseModel {
    name: string;
    code: string;
    agent_report_name: string;
    is_active?: number | boolean;
}

export class ServiceType extends BaseModel {
    service_category_id: number;
    name: string;
    code: string;
    is_active?: number | boolean;
}

export class ServiceContractor extends BaseModel {
    name: string;
    full_name: string;
    is_active?: number | boolean;
    notes?: string;
}

export class ServiceRequest extends BaseModel {
    /** @var int Новый */
    public static readonly STATUS_NEW = 1;

    /** @var int Подтверждён */
    public static readonly STATUS_CONFIRMED = 2;

    /** @var int Согласован с получателем */
    public static readonly STATUS_APPROVED_BY_RECIPIENT = 3;

    /** @var int Согласован подрядчиком */
    public static readonly STATUS_APPROVED_BY_CONTRACTOR = 4;

    /** @var int Выполняется */
    public static readonly STATUS_IN_PROGRESS = 5;

    /** @var int Выполнен */
    public static readonly STATUS_PROVIDED = 6;

    /** @var int Частичный отказ */
    public static readonly STATUS_PARTIAL_REFUSE = 7;

    /** @var int Отказ на месте */
    public static readonly STATUS_FULL_REFUSE = 8;

    /** @var int Отмена */
    public static readonly STATUS_CANCELED = 9;

    /**
     * Возможные статусы заявки на оказание услуг
     */
    public static readonly STATUSES: IBaseRecordStatus[] = [
        {id: ServiceRequest.STATUS_NEW, name: 'Новая', classes: 'badge badge-muted', _special: true},
        {id: ServiceRequest.STATUS_CONFIRMED, name: 'Подтверждена', classes: 'badge badge-primary'},
        {id: ServiceRequest.STATUS_APPROVED_BY_RECIPIENT, name: 'Согласована с получателем', classes: 'badge badge-info'},
        {id: ServiceRequest.STATUS_APPROVED_BY_CONTRACTOR, name: 'Согласована с подрядчиком', classes: 'badge badge-info'},
        {id: ServiceRequest.STATUS_IN_PROGRESS, name: 'Выполняется', classes: 'badge badge-info'},
        {id: ServiceRequest.STATUS_PROVIDED, name: 'Выполнена', classes: 'badge badge-success'},
        {id: ServiceRequest.STATUS_PARTIAL_REFUSE, name: 'Частичный отказ', classes: 'badge badge-warning'},
        {id: ServiceRequest.STATUS_FULL_REFUSE, name: 'Отказ на месте', classes: 'badge badge-danger'},
        {id: ServiceRequest.STATUS_CANCELED, name: 'Отмена', classes: 'badge badge-danger'},
    ];

    order_id: number;
    contractor_id?: number;
    request_uid?: string;
    status: number;
    address_id: number;
    address_text: string;
    lat?: number;
    lon?: number;
    beltway_distance?: number;
    zone_id?: number;
    zone_group_id?: number;
    date: string;
    time_start: number;
    time_end: number;
    target_name: string;
    target_contacts: string;
    target_email?: string;
    target_contacts_reversed?: string;
    second_phone?: string;
    organization_name?: string;
    recipient_name?: string;
    client_notes?: string;
    operator_notes?: string;
    contractor_notes?: string;

    services?: ServiceRequestItem[];

    order?: Order;
    contractor?: ServiceContractor;
}

export class ServiceRequestItem extends BaseModel {
    /** @var int Ожидание */
    public static readonly STATUS_PENDING = 1;

    /** @var int Услуги оказаны */
    public static readonly STATUS_PROVIDED = 2;

    /** @var int Отказ на месте */
    public static readonly STATUS_REFUSED = 3;

    /** @var int Отмена */
    public static readonly STATUS_CANCELED = 4;

    /**
     * Возможные статусы заявки на оказание услуг
     */
    public static readonly STATUSES: IBaseRecordStatus[] = [
        {id: ServiceRequestItem.STATUS_PENDING, name: 'Ожидание', classes: 'badge badge-primary', _special: true},
        {id: ServiceRequestItem.STATUS_PROVIDED, name: 'Услуги оказаны', classes: 'badge badge-success'},
        {id: ServiceRequestItem.STATUS_REFUSED, name: 'Отказ на месте', classes: 'badge badge-danger'},
        {id: ServiceRequestItem.STATUS_CANCELED, name: 'Отмена', classes: 'badge badge-danger'},
    ];

    order_id: number;
    service_request_id: number;
    service_id: number;
    quantity: number;
    price: number;
    declared_price: number;
    tax_type: number;
    status: number;
    details?: string;

    order?: Order;
    request?: ServiceRequest;
}

export interface IFeeBreakdownStatsItem {
    sum_tariff_logistics: number;
    sum_tariff_cod: number;
    sum_tariff_addons: number;
    sum_tariff_total: number;
    sum_tariff_total_nds: number;
    sum_tariff_total_with_nds: number;

    client_uid?: string;
    client_name?: string;
}

export interface IFeeBreakdownStatsPayload {
    total: IFeeBreakdownStatsItem;
    by_clients: IFeeBreakdownStatsItem[];
}
