import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {FinanceService} from '../../../service/finance.service';
import {FileDownloadService} from '../../../http/file-download.service';

@Component({
  selector: 'app-payments-report-zorders-popup',
  templateUrl: './payments-report-zorders-popup.component.html',
  styleUrls: ['./payments-report-zorders-popup.component.scss']
})
export class PaymentsReportZordersPopupComponent implements OnInit {
    @Input('query') query;

    public width = 500;
    public windowTop = 100;
    public windowHeight = 100;
    public title = 'Список заборов';

    public zorders;

    public zordersLoading: boolean = false;
    public isExcelLoading = false;

    constructor(
        protected app: AppComponent,
        private financeApi: FinanceService,
        private fileDownloadService: FileDownloadService,
    ) {
        if (window.innerWidth > 800) {
            this.width = 800;
        } else {
            this.width = (0.95 * window.innerWidth);
        }

        this.windowHeight = (window.innerHeight - this.windowTop) * 0.9;
    }

    ngOnInit() {
        this.zordersLoading = true;
        this.financeApi.getPaymentsReportZordersList(this.query).subscribe(data => {
            this.zorders = data;
            this.zordersLoading = false;
        }, error => {
            this.zordersLoading = false;
        });
    }

    onClose() {
        this.app.closeDialog(this);
    }

    onExcelClick() {
        this.isExcelLoading = true;
        const query = this.query;
        const fileName = 'Отчёт по оплатам. Расшифровка заборов ' + query.date_from + ' - ' + query.date_to;

        this.fileDownloadService
            .downloadFile('/buh/getPaymentsReportZordersListExcel', fileName + '.xlsx', 'post', query)
            .subscribe(response => {
                this.isExcelLoading = false;
            }, error => {
                this.isExcelLoading = false;
            });
    }
}
