import {Injectable} from '@angular/core';
import {LaraService} from './lara.service';
import {Observable} from 'rxjs';
import {IBankPaymentOrderSpreadingSuggestionPayload} from '../finance/finance-models';

@Injectable({
    providedIn: 'root',
})
export class FinanceService extends LaraService {

    /**
     * Сверка листа закрытия с расчетом курьера
     * @param date
     */
    public getDifference(date) {
        return this.get('/buh/getDifference', {date: date});
    }

    /**
     * Статистика для расчёта курьера
     * @param date
     * @param courier_id
     */
    public getCourierMoneyCheck(date, courier_id) {
        return this.get('/buh/getMoneyCheck', {date: date, courier_id: courier_id});
    }

    /**
     * Получить список штрафов по курьеру
     * @param date
     * @param courier_id
     */
    public getCourierPenalties(date, courier_id) {
        return this.get('/buh/getPenalties', {date: date, courier_id: courier_id});
    }

    /**
     * Установить ЗП курьера
     * @param salary
     */
    public setCourierSalary(salary) {
        return this.post('/buh/saveCourierSalary', salary);
    }

    /**
     * Изменение полей кассиром, связанных с зарплатой
     *
     * @param data
     */
    public setCourierSalaryField(data) {
        return this.post('/buh/saveCourierSalaryField', data);
    }


    /**
     * Логи по изменению ЗП курьера
     * @param date
     * @param courier_id
     */
    public getCourierSalaryLog(date, courier_id) {
        return this.get('/buh/getCourierSalaryLog', {date: date, courier_id: courier_id});
    }

    /**
     * Записать логи по ЗП курьера
     * @param salary
     */
    public saveCourierSalaryLog(salary) {
        return this.post('/buh/saveCourierSalaryLog', salary);
    }

    /**
     * Удалить ЗП курьера
     * @param salaryId
     */
    public deleteCourierSalary(salaryId) {
        return this.post(`/buh/deleteCourierSalary/${salaryId}`, []);
    }

    /**
     * Список ХУ заказов
     * @param start_date
     * @param end_date
     */
    public getTroubleOrders(start_date, end_date) {
        return this.get('/buh/getProblemOrders', {start_date: start_date, end_date: end_date});
    }

    /**
     * Лист закрытия курьеров
     * @param start_date
     * @param end_date
     * @param hub_id
     * @param selectedCourierTypeIds
     */
    public getCourierCloseList(start_date, end_date, hub_id, selectedCourierTypeIds) {
        return this.get('/buh/getCloseListForCashier',
            {
                start_date: start_date,
                end_date: end_date,
                hub_id: hub_id,
                'courier_type_ids[]': selectedCourierTypeIds
            }
        );
    }

    /**
     * Лист закрытия курьеров для логистов
     * @param start_date
     * @param end_date
     * @param hub_id
     * @param selectedCourierTypeIds
     */
    public getLogistCloseList(start_date, end_date, hub_id, selectedCourierTypeIds) {
        return this.get('/buh/getCloseListForLogist',
            {
                start_date: start_date,
                end_date: end_date,
                hub_id: hub_id,
                'courier_type_ids[]': selectedCourierTypeIds
            }
        );
    }

    /**
     * Список сдвигов курьеров
     * @param cr_id
     * @param hour
     * @param startdate
     * @param enddate
     */
    public getSdvg(cr_id, hour, startdate, enddate) {
        return this.get('/buh/getSdvg', {
            end_date: enddate, start_date: startdate,
            courier_id: cr_id, hour: hour,
        });
    }

    /**
     * Список недозвонов
     * @param courierId
     * @param startdate
     * @param enddate
     */
    public getCustomerNotResponded(courierId, startdate, enddate) {
        return this.get('/buh/getCustomerNotResponded', {
            end_date: enddate, start_date: startdate,
            courier_id: courierId,
        });
    }

    /**
     * Список сдвинутых интервалов курьера
     * @param cr_id
     * @param startdate
     * @param enddate
     */
    public getNotInInterval(cr_id, startdate, enddate) {
        return this.get('/buh/getNotInInterval', {
            end_date: enddate, start_date: startdate,
            courier_id: cr_id,
        });
    }


    /**
     * Список косячных заказов курьеров
     * @param cr_id
     * @param startdate
     * @param enddate
     */
    public getKos(cr_id, startdate, enddate) {
        return this.get('/buh/getKos', {end_date: enddate, start_date: startdate, courier_id: cr_id});
    }

    /**
     * Получить внесения денег в электронного кассира
     * @param params
     */
    public getEkassirDeposits(params) {
        return this.post('/e-kassir/stat', params);
    }

    /**
     * Получить данные о последней инкассации
     */
    public getEncashmentInfo(params) {
        return this.post('/e-kassir/stat/getEncashmentInfo', params);
    }

    /**
     * Провести инкассацию
     * @param params
     */
    public processEncashment(params) {
        return this.post('/e-kassir/processEncashment', params);
    }

    /**
     * Отчёт по кассе
     * @param params
     */
    public getKassaReport(params) {
        return this.post('/buh/kassaReport', params);
    }

    /** Чеки налички Орандж */
    public getOrangeCashReceipts(params) {
        return this.post('/buh/kassaReport/orangeCashReceipts', params);
    }

    /** Чеки безнал Орандж */
    public getOrangeCardReceipts(params) {
        return this.post('/buh/kassaReport/orangeCardReceipts', params);
    }

    /** Чеки Apiship */
    public getApishipCashReceipts(params) {
        return this.post('/buh/kassaReport/apishipCashReceipts', params);
    }

    /** Платежи Ibox */
    public getIboxPayments(params) {
        return this.post('/buh/kassaReport/iboxPayments', params);
    }

    /** Платежи Assist */
    public getAssistPayments(params) {
        return this.post('/buh/kassaReport/assistPayments', params);
    }

    /** Плитежи СБП */
    public getLifepayPayments(params) {
        return this.post('/buh/kassaReport/lifepayPayments', params);
    }

    /** Список проблем в отчёте по кассе */
    public getKassaReportProblems(params) {
        return this.post('/buh/kassaReport/problems', params);
    }

    /** Пересчёт заказов и заборов */
    public recalcOrders(params) {
        return this.post('/buh/recalcOrders', params);
    }

    public recalcZorders(params) {
        return this.post('/buh/recalcZorders', params);
    }

    /** Переданные заказы */
    getTransferOrders(params) {
        return this.post('/buh/transferOrders', params);
    }

    /** Агентские отчёты */
    public getAgentReports(params) {
        return this.get('/agentReports', params);
    }

    public getSummaryAgentReport(params) {
        return this.post('/agentReports/summary', params);
    }

    public getAgentReport(id) {
        return this.get('/agentReports/' + id);
    }

    public updateAgentReport(id, params) {
        return this.post('/agentReports/' + id, params);
    }

    public refreshAgentReport(id) {
        return this.post('/agentReports/' + id + '/refresh', {});
    }

    public deleteAgentReport(id) {
        return this.delete('/agentReports/' + id);
    }

    public getLastAgentReport(params) {
        return this.get('/agentReports/getLastReport', params);
    }

    public createAgentReport(params) {
        return this.post('/agentReports', params);
    }

    public getAgentPayments(params) {
        return this.get('/agentReports/payments', params);
    }

    public getAgentReportsRevision(params) {
        return this.get('/agentReports/revision', params);
    }

    public getClientListForReports() {
        return this.get('/agentReports/getClientListForReports');
    }

    /** /Агентские отчёты */


    /** Платёжные поручения */
    /**
     * Список платёжных поручений
     */
    public listBankPaymentOrders(params) {
        return this.post('/bank-payment-orders/list', params);
    }

    /**
     * Информация о платёжном поручении
     * @param id
     */
    public getBankPaymentOrderItem(id) {
        return this.get('/bank-payment-orders/' + id);
    }

    /**
     * Загрузка банковской выписки
     * @param params
     */
    public uploadBankPaymentOrdersExchangeData(params) {
        return this.post('/bank-payment-orders/upload', params);
    }

    /**
     * Удаление платёжного поручения
     * @param id
     */
    deleteBankPaymentOrder(id) {
        return this.post('/bank-payment-orders/' + id + '/delete', {});
    }

    /**
     * Восстановление платёжного поручения
     * @param id
     */
    public restoreBankPaymentOrder(id) {
        return this.post('/bank-payment-orders/' + id + '/restore', {});
    }

    /**
     * Верификация платёжного поручения
     * @param id
     */
    public verifyBankPaymentOrder(id) {
        return this.post('/bank-payment-orders/' + id + '/verify', {});
    }

    /**
     * Удаление записи разнесения платёжного поручения к агентскому отчёту
     * @param id
     */
    public deleteAgentReportBankPaymentOrderPart(id) {
        return this.post('/bank-payment-orders/parts/' + id + '/delete', {});
    }

    /**
     * Восстановление записи разнесения платёжного поручения к агентскому отчёту
     * @param id
     */
    public restoreAgentReportBankPaymentOrderPart(id) {
        return this.post('/bank-payment-orders/parts/' + id + '/restore', {});
    }

    /**
     * Верификация разнесения платёжного поручения к агентскому отчёту
     * @param id
     */
    public verifyAgentReportBankPaymentOrderPart(id) {
        return this.post('/bank-payment-orders/parts/' + id + '/verify', {});
    }

    /**
     * Получение данных для нового перевода наложенных платежей
     * @param clientId
     */
    public getDataForNewBankPaymentOrder(clientId) {
        return this.get('/bank-payment-orders/get-data-for-new-bank-payment-order/' + clientId);
    }

    /**
     * Создание записи платёжного поручения
     * @param params
     */
    public createBankPaymentOrder(params) {
        return this.post('/bank-payment-orders/create', params);
    }

    /**
     * Получение данных для разнесения суммы платёжного поручения к агентским отчётам
     * @param id
     */
    public getDataForSpreadingBankPaymentOrder(id): Observable<IBankPaymentOrderSpreadingSuggestionPayload> {
        return this.get('/bank-payment-orders/' + id + '/get-data-for-spreading');
    }

    /**
     * Разнесения суммы платёжного поручения к агентским отчётам
     * @param id
     * @param params
     */
    public spreadBankPaymentOrder(id, params) {
        return this.post('/bank-payment-orders/' + id + '/spread', params);
    }


    /** / Платёжные поручения */

    getPaymentsReport(params) {
        return this.post('/buh/getPaymentsReport', params);
    }
    getPaymentsReportOrders(params) {
        return this.post('/buh/getPaymentsReportOrders', params);
    }
    getPaymentsReportZorders(params) {
        return this.post('/buh/getPaymentsReportZorders', params);
    }
    getPaymentsReportZordersList(params) {
        return this.post('/buh/getPaymentsReportZordersList', params);
    }
    /** /Отчёт по оплатам */
}
